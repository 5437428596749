<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import EventBus from '../../event-bus'
import notificationMixin from '@/mixins/notificationMixin';
import Repository from "../../app/repository/repository-factory";
import {required} from "vuelidate/lib/validators";

const ApplicationSettlementRepository = Repository.get("ApplicationSettlementRepository");
const MIN_AMOUNT = 0.01;

export default {
  components: {DatePicker},
  mixins: [notificationMixin],
  props: {
    settlementId: {
      type: String,
      required: true
    },
    settlementExpectedVersion: {
      type: Number,
      required: true
    },
    remainingToBePaid: {
      type: Number,
      required: false
    },
    applicationId: {
      type: String,
      required: true
    },
    settlement: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isBusy: false,
      showConfirmModal: false,
      submitted: false,
      form: {
        date: this.formatCurrentDate()
      },
      preOperationAmount: 0
    };
  },
  validations: {
    form: {
      date: {required}
    }
  },
  methods: {
    formatCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0');
      const day = String(today.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    
    formatCurrency(value) {
      return Number(value).toFixed(2);
    },
    
    resetForm() {
      this.form.date = this.formatCurrentDate();
      this.submitted = false;
      this.showConfirmModal = false;
    },
    
    initAutomaticWithdrawal() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }
      
      // Pokazujemy modal potwierdzający
      this.showConfirmModal = true;
    },
    
    async makeAutomaticWithdrawal() {
      try {
        this.isBusy = true;
        this.showConfirmModal = false;
        
        // Zapamiętujemy kwotę przed operacją
        this.preOperationAmount = this.remainingToBePaid;

        let payload = {
          'date': this.form.date,
          'version': this.settlementExpectedVersion
        }

        // Wywołujemy API dla automatycznej wypłaty
        await ApplicationSettlementRepository.makeAutomaticWithdrawal(this.settlementId, payload);
        
        // Emitujemy event na przeładowanie danych w komponencie nadrzędnym
        await EventBus.$emit('applicationSettlementChanged', '');
        
        // Pobieramy aktualne dane rozliczenia z API
        await this.fetchCurrentSettlementData();
      } catch (error) {
        this.notifyError(
          error.response?.data?.message || 'Wystąpił błąd podczas realizacji automatycznej wypłaty',
          { timer: 5000 }
        );
        this.isBusy = false;
      }
    },
    async fetchCurrentSettlementData() {
      try {
        const response = await ApplicationSettlementRepository.getItem(this.applicationId);
        
        if (response && response.data) {
          const settlement = response.data;
          
          // Obliczamy aktualną kwotę do wypłaty
          const totalWithdrawals = settlement.withdrawals.reduce((sum, w) => sum + w.amount, 0);
          const currentAmount = Number((settlement.amountToSettled - totalWithdrawals).toFixed(2));
          
          this.analyzeWithdrawalResults(currentAmount);
        } else {
          this.notifySuccess('Brak danych o rozliczeniu. Sprawdź tabelę wypłat poniżej.', { timer: 3000 });
          this.resetForm();
          this.isBusy = false;
        }
      } catch (error) {
        console.error("Błąd podczas pobierania danych rozliczenia:", error);
        this.notifySuccess('Automatyczna wypłata została zakończona. Sprawdź tabelę wypłat poniżej.', { timer: 3000 });
        this.resetForm();
        this.isBusy = false;
      }
    },
    analyzeWithdrawalResults(currentAmount) {
      try {
        const prevAmount = this.preOperationAmount;
        const formattedPrevAmount = this.formatCurrency(prevAmount);
        
        // Obliczamy różnicę
        const diff = prevAmount - currentAmount;
        
        // Jeśli różnica jest praktycznie zerowa, to nic nie zostało wypłacone
        if (diff < MIN_AMOUNT) {
          this.notifySuccess(
            'Nie udało się zrealizować automatycznej wypłaty. Prawdopodobnie brak wystarczających środków na kontach beneficjenta.', 
            { timer: 3000 }
          );
        }
        // Jeśli wypłacono jakąkolwiek kwotę
        else if (diff > 0) {
          const formattedDiff = this.formatCurrency(diff);
          
          // Jeśli nie ma już nic do wypłaty lub wypłacono prawie całość
          if (currentAmount <= MIN_AMOUNT) {
            this.notifySuccess(
              `Automatyczna wypłata: zrealizowano ${formattedDiff} zł - wypłacono pełną kwotę.`, 
              { timer: 3000 }
            );
          } else {
            // Wypłata częściowa
            const formattedCurrentAmount = this.formatCurrency(currentAmount);
            this.notifySuccess(
              `Automatyczna wypłata: zrealizowano ${formattedDiff} zł z ${formattedPrevAmount} zł. Pozostało do wypłaty: ${formattedCurrentAmount} zł.`, 
              { timer: 3000 }
            );
          }
        } else {
          // Niespodziewany przypadek
          this.notifySuccess('Zakończono operację wypłaty. Sprawdź tabelę wypłat poniżej.', { timer: 3000 });
        }
      } catch (error) {
        console.error("Błąd podczas analizy wyników:", error);
        this.notifySuccess('Automatyczna wypłata została zakończona. Sprawdź tabelę wypłat poniżej.', { timer: 3000 });
      } finally {
        this.resetForm();
        this.isBusy = false;
      }
    },
    
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.form.$touch()
    },
    cancelOperation() {
      this.showConfirmModal = false;
      this.isBusy = false;
    }
  }
};
</script>
<template>
  <div>
    <form class="needs-validation" @submit.prevent="formSubmit">
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <label for="auto-date" class="sr-only">Data wypłaty</label>
            <date-picker
              id="auto-date"
              v-model="form.date"
              placeholder="Wybierz datę wypłaty"
              :first-day-of-week="1"
              value-type="format"
              :class="{ 'is-invalid': submitted && $v.form.date.$error }"
              lang="pl"
              aria-label="Data wypłaty"
              >
            </date-picker>
            <div v-if="submitted && $v.form.date.$error" class="invalid-feedback">
              <span v-if="!$v.form.date.required">Ta wartość jest wymagana.<br></span>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div>
              <button 
                type="button" 
                class="btn btn-primary btn-block waves-effect waves-light"
                v-on:click="initAutomaticWithdrawal"
                :disabled="isBusy"
                aria-label="Przycisk wypłaty automatycznej"
              >
                <i class="bx bx-check-double mr-1"></i> Wypłać automatycznie
                <template v-if="isBusy">
                  <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
                </template>
              </button>
              <small class="form-text text-muted">
                Wypłaci kwotę {{ formatCurrency(remainingToBePaid) }} zł z dostępnych kont w optymalnej kolejności.
                <i class="bx bx-info-circle" title="System próbuje wypłacić maksymalną możliwą kwotę z dostępnych kont beneficjenta w kolejności od najstarszych środków."></i>
              </small>
            </div>
          </div>
        </div>
      </div>
    </form>
    
    <!-- Modal potwierdzający operację -->
    <b-modal 
      v-model="showConfirmModal" 
      title="Potwierdź automatyczną wypłatę" 
      ok-title="Wykonaj wypłatę" 
      cancel-title="Anuluj"
      @ok="makeAutomaticWithdrawal"
      @cancel="cancelOperation"
      :ok-disabled="isBusy"
      :cancel-disabled="isBusy"
    >
      <p>Czy na pewno chcesz wykonać automatyczną wypłatę na kwotę <strong>{{ formatCurrency(remainingToBePaid) }} zł</strong> w dniu <strong>{{ form.date }}</strong>?</p>
      <p class="mb-0">System spróbuje wypłacić maksymalną możliwą kwotę z dostępnych kont beneficjenta.</p>
      <template v-if="isBusy" #footer>
        <div class="text-center w-100">
          <b-spinner small class="mr-2"></b-spinner>
          Trwa przetwarzanie...
        </div>
      </template>
    </b-modal>
  </div>
</template> 
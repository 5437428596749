<script>
import EventBus from '../../event-bus';
import Repository from "../../app/repository/repository-factory";
import { required } from "vuelidate/lib/validators";
import notificationMixin from '@/mixins/notificationMixin';

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  components: {},
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    },
    applicationStatuses: {
      type: Array,
      required: true
    },
    statuses: {
      type: Array,
      required: true
    },
    settlementStatus: {
      type: String,
      default: null,
    },
    settlementDate: {
      type: [String, Date],
      default: null
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isCreatedByGuardian: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isBusy: false,
      submitted: false,
      form: {
        status: null,
      },
    };
  },
  validations: {
    form: {
      status: {required},
    }
  },
  computed: {
    allowedStatusOptions() {
      return [
        { value: null, text: 'Wybierz status' },
        ...this.allowStatus()
      ];
    },
    sortedStatuses() {
      return [...this.applicationStatuses].reverse();
    },
    currentStatus() {
      return this.settlementStatus || this.applicationStatuses.slice(-1)[0]?.statusName || 'in_preparation';
    }
  },
  methods: {
    changeStatus(keyStatus) {
      return this.statuses.find(element => element.value === keyStatus)?.text || '';
    },
    changeApplicationStatus() {
      this.formSubmit();

      if (this.$v.form.$error) return;

      this.isBusy = true;

      const payload = {
        'status': this.form.status,
        'version': this.applicationExpectedVersion,
      }

      ApplicationApplicationRepository.changeApplicationStatus(this.applicationId, payload)
        .then(() => {
          EventBus.$emit('applicationChanged', '');
          EventBus.$emit('applicationSettlementChanged', '');
          this.notifySuccess('Status wniosku został zmieniony!');
        })
        .catch(error => {
          this.notifyError(error.response.data.message);
        })
        .finally(() => {
          this.isBusy = false;
        });
    },
    formSubmit() {
      this.submitted = true;
      this.$v.form.$touch()
    },
    allowStatus() {
      const currentStatus = this.currentStatus;
      const statusMap = {
        'in_preparation': this.isCreatedByGuardian ? [] : ['for_approval', 'incorrect'],
        'submitted_by_guardian': ['for_approval', 'incorrect'],
        'for_approval': ['approved', 'correction', 'incorrect'],
        'correction': ['for_approval', 'correction', 'incorrect']
      };
      
      const allowedStatuses = statusMap[currentStatus] || [];
      return allowedStatuses.map(status => 
        this.statuses.find(element => element.value === status)
      ).filter(Boolean);
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString.replace(' ', 'T'));
      return new Intl.DateTimeFormat('pl-PL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date);
    },
    formatDateShort(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString.replace(' ', 'T'));
      return new Intl.DateTimeFormat('pl-PL', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date).replace(',', '');
    }
  },
  mixins: [notificationMixin]
};
</script>
<template v-if="isBusy === false">
  <form class="needs-validation" @submit.prevent="formSubmit">
    <b-input-group v-if="allowStatus().length > 0" class="mb-3">
      <b-form-select
        v-model="form.status"
        :options="allowedStatusOptions"
        :class="{ 'is-invalid': submitted && $v.form.status.$error }">
      </b-form-select>
      <b-input-group-append>
        <b-button variant="primary" @click="changeApplicationStatus" :disabled="disabled">
          Zmień
          <b-spinner v-if="isBusy" small variant="light" role="status" class="ml-2"/>
        </b-button>
      </b-input-group-append>
    </b-input-group>
    <div v-if="allowStatus().length > 0 && submitted && $v.form.status.$error" class="invalid-feedback d-block">
      <span v-if="!$v.form.status.required">Ta wartość jest wymagana.</span>
    </div>

    <div class="row">
      <div class="col-md-12 col-lg-12">
        <div class="timeline">
          <b-list-group-item 
            v-if="settlementStatus" 
            :key="`settlement-${settlementStatus}`"
            class="status-item"
            :class="{ 'fw-bold': settlementStatus === currentStatus }"
          >
            <div class="timeline-item">
              <div class="timeline-marker"></div>
              <div class="timeline-content">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <div class="status-text mb-0">{{ changeStatus(settlementStatus) }}</div>
                  </div>
                  <div class="status-date" v-if="settlementDate">
                    <i class="bx bx-time me-1"></i>
                    {{ formatDateShort(settlementDate) }}
                  </div>
                </div>
              </div>
            </div>
          </b-list-group-item>

          <b-list-group-item 
            v-for="(status, index) in sortedStatuses"
            :key="`${status.statusName}-${index}`"
            class="status-item"
            :class="{ 'fw-bold': !settlementStatus && index === 0 }"
          >
            <div class="timeline-item">
              <div class="timeline-marker"></div>
              <div class="timeline-content">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center gap-3">
                    <b-tooltip :target="'status-' + index" placement="left">
                      <div class="text-start">
                        <div><strong>Zmieniono przez:</strong> {{ status.createdBy }}</div>
                        <div><strong>Data zmiany:</strong> {{ formatDate(status.createdAt) }}</div>
                      </div>
                    </b-tooltip>
                    <div :id="'status-' + index" class="status-text mb-0">{{ changeStatus(status.statusName) }}</div>
                  </div>
                  <div class="status-date">
                    <i class="bx bx-time me-1"></i>
                    {{ formatDateShort(status.createdAt) }}
                  </div>
                </div>
              </div>
            </div>
          </b-list-group-item>
        </div>
      </div>
    </div>

  </form>
</template>

<style scoped>
  .timeline {
    position: relative;
    padding: 0.25rem 0;
  }

  .timeline::before {
    content: '';
    position: absolute;
    left: 0.53rem;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #eef0f3;
  }

  .status-item {
    padding: 0.25rem 0;
    border: none;
    background: transparent;
  }

  .timeline-item {
    position: relative;
    padding-left: 1.75rem;
    margin-bottom: 0.4rem;
  }

  .timeline-marker {
    position: absolute;
    left: 0.53rem;
    width: 0.4rem;
    height: 0.4rem;
    border-radius: 50%;
    background: #6366f1;
    border: 1px solid #fff;
    box-shadow: 0 0 0 1px #6366f1;
    transform: translateX(-50%) translateY(0.55rem);
  }

  .timeline-content {
    background: #fff;
    padding: 0.45rem 0.6rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: all 0.2s ease;
    line-height: 1;
  }

  .status-text {
    font-size: 0.8125rem;
    color: #1f2937;
    line-height: 1;
  }

  .status-date {
    color: #9ca3af;
    font-size: 0.6875rem;
    display: flex;
    align-items: center;
    white-space: nowrap;
    letter-spacing: -0.2px;
    line-height: 1;
  }

  .fw-bold .timeline-content {
    background: #f8fafc;
    border: 1px solid #e5e7eb;
  }

  .fw-bold .status-text {
    color: #111827;
    font-weight: 500;
  }

  .timeline-content:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }

  .gap-3 {
    gap: 0.5rem;
  }

  .bx {
    font-size: 0.75rem;
  }
</style>

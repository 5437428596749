<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import EventBus from '../../event-bus'
import notificationMixin from '@/mixins/notificationMixin';
import floatInputMixin from '@/mixins/floatInputMixin';
import Repository from "../../app/repository/repository-factory";
import {required, minValue} from "vuelidate/lib/validators";

const ApplicationSettlementRepository = Repository.get("ApplicationSettlementRepository");
const greaterThanZero = (value) => value > 0;

export default {
  components: {DatePicker},
  mixins: [notificationMixin, floatInputMixin],
  props: {
    settlementId: {
      type: String,
      required: true
    },
    settlementExpectedVersion: {
      type: Number,
      required: true
    },
    remainingToBePaid: {
      type: Number,
      required: false
    }
  },
  data() {
    const today = new Date();
    return {
      isBusy: false,
      submitted: false,
      form: {
        beneficiaryAccountType: '',
        date: today,
        amount: ''
      },
      typeAccount: [
        {value: '', text: 'Wybierz typ konta'},
        {value: 1, text: 'Konto imienne'},
        {value: 2, text: 'Zbiórka publiczna'},
        {value: 3, text: 'Konto 1,5%'},
        {value: 4, text: 'Konto fundacji'}
      ]
    };
  },
  validations: {
    form: {
      beneficiaryAccountType: {required, minValue: minValue(1)},
      date: {required},
      amount: {
        required,
        float: value => /^\d{1,10}([,.]\d{0,2})?$/.test(value),
        greaterThanZero
      }
    }
  },
  methods: {
    resetForm() {
      this.form.amount = '';
      this.form.date = '';
      this.form.beneficiaryAccountType = '';
      this.submitted = false;
    },
    makeWithdrawal() {
      this.formSubmit();

      if (this.$v.form.$error === true) {
        return false;
      }

      this.isBusy = true;

      let payload = {
        'beneficiaryAccountType': this.form.beneficiaryAccountType,
        'date': this.form.date,
        'amount': this.form.amount,
        'version': this.settlementExpectedVersion
      }

      ApplicationSettlementRepository.makeWithdrawal(this.settlementId, payload)
        .then(async () => {
          await EventBus.$emit('applicationSettlementChanged', '');
          
          // Prosty komunikat o sukcesie
          this.notifySuccess(`Wypłata z "${this.getAccountTypeName(this.form.beneficiaryAccountType)}" została zrealizowana!`, { timer: 2000 });
          
          this.resetForm();
          this.isBusy = false;
        })
        .catch(error => {
          this.notifyError(error.response?.data?.message || 'Wystąpił błąd podczas realizacji wypłaty', { timer: 5000 });
          this.isBusy = false;
        });
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.form.$touch()
    },
    assignRemainingToBePaid() {
      if (this.remainingToBePaid > 0) {
        this.form.amount = this.remainingToBePaid;
      }
    },
    handleAmountInput(event) {
      this.form.amount = this.handleFloatInput(event);
    },
    getAccountTypeName(typeId) {
      const accountType = this.typeAccount.find(type => type.value === parseInt(typeId));
      return accountType ? accountType.text.toLowerCase() : 'wybranego';
    },
    assignTodayDate() {
      this.form.date = new Date();
    }
  }
};
</script>
<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-4">
        <div class="form-group">
          <b-form-select
            id="account-type"
            v-model="form.beneficiaryAccountType"
            :options="typeAccount"
            :class="{ 'is-invalid': submitted && $v.form.beneficiaryAccountType.$error }">
          </b-form-select>
          <div v-if="submitted && $v.form.beneficiaryAccountType.$error" class="invalid-feedback">
            <span v-if="!$v.form.beneficiaryAccountType.required">Ta wartość jest wymagana.</span>
            <span v-if="!$v.form.beneficiaryAccountType.minValue">Musisz wybrać konkretny typ konta.</span>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <div class="input-group">
            <input
              id="amount"
              v-model="form.amount"
              type="text"
              class="form-control"
              placeholder="Wpisz kwotę"
              @input="handleAmountInput"
              :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
              v-on:dblclick="assignRemainingToBePaid()"
            />
            <div class="input-group-append">
              <span class="input-group-text">zł</span>
            </div>
            <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
              <span v-if="!$v.form.amount.required">Ta wartość jest wymagana.</span>
              <span v-if="!$v.form.amount.float">Wpisz poprawną kwotę (max. 2 miejsca po przecinku)</span>
              <span v-if="!$v.form.amount.greaterThanZero">Wartość musi być większa od 0.</span>
            </div>
          </div>
          <small class="form-text text-muted">Dwukrotne kliknięcie wstawia całą kwotę do wypłaty.</small>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group">
          <date-picker
            id="manual-date"
            v-model="form.date"
            placeholder="Wybierz datę"
            :first-day-of-week="1"
            format="YYYY-MM-DD"
            value-type="date"
            :class="{ 'is-invalid': submitted && $v.form.date.$error }"
            lang="pl">
          </date-picker>
          <div v-if="submitted && $v.form.date.$error" class="invalid-feedback">
            <span v-if="!$v.form.date.required">Ta wartość jest wymagana.<br></span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <button 
          type="button" 
          class="btn btn-primary btn-block waves-effect waves-light"
          v-on:click="makeWithdrawal"
          :disabled="isBusy"
        >
          <i class="bx bx-transfer mr-1"></i> Wypłać ręcznie
          <template v-if="isBusy">
            <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
          </template>
        </button>
      </div>
    </div>
  </form>
</template>

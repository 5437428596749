export default {
  methods: {
    handleFloatInput(event, maxDigits = 10) {
      // Zamiana przecinka na kropkę i usunięcie nieprawidłowych znaków
      let value = event.target.value.replace(/[^\d,.]/g, '').replace(',', '.');
      
      // Sprawdzenie czy nie ma więcej niż jednej kropki
      const dotCount = (value.match(/\./g) || []).length;
      if (dotCount > 1) {
        value = value.substring(0, value.lastIndexOf('.'));
      }
      
      // Ograniczenie do dwóch miejsc po przecinku
      if (value.includes('.')) {
        const [whole, decimal] = value.split('.');
        value = `${whole}.${decimal.substring(0, 2)}`;
      }

      // Ograniczenie do maxDigits cyfr przed przecinkiem
      if (value.includes('.')) {
        const [whole] = value.split('.');
        if (whole.length > maxDigits) {
          value = value.substring(0, maxDigits) + value.substring(value.indexOf('.'));
        }
      } else if (value.length > maxDigits) {
        value = value.substring(0, maxDigits);
      }

      return value;
    }
  }
}; 
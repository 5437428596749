<script>
export default {
  name: 'FilePreviewModal',
  props: {
    title: {
      type: String,
      default: 'Podgląd'
    },
    loadPreviewCallback: {
      type: Function,
      required: true
    },
    downloadCallback: {
      type: Function,
      required: true
    },
    fileInfo: {
      type: Object,
      required: false,
      default: () => null,
      validator: obj => !obj || (obj.filename && obj.extension)
    },
    modalSize: {
      type: Object,
      default: () => ({
        width: '90vw',
        height: '90vh'
      })
    },
    position: {
      type: String,
      default: 'center',
      validator: value => ['left', 'center', 'right'].includes(value)
    },
    overlayOpacity: {
      type: Number,
      default: 0.5,
      validator: value => value >= 0 && value <= 1
    },
    hideElementId: String,
    expandElementId: String,
    expandElementClass: {
      type: String,
      default: 'col-lg-12'
    },
    originalElementClass: {
      type: String,
      default: 'col-lg-9'
    }
  },
  data() {
    return {
      isLoading: false,
      error: null,
      fileUrl: null,
      zoomLevel: 1,
      isVisible: false,
      originalLayout: null,
      isPanning: false,
      offsetX: 0,
      offsetY: 0,
      lastX: 0,
      lastY: 0,
      rotation: 0,
      initialScaleApplied: false,
      naturalWidth: 0,
      naturalHeight: 0,
      userSelectedZoom: false,
      userZoomLevel: 1,
      originalOrientation: null
    };
  },
  computed: {
    fileType() {
      if (!this.fileInfo) return { isPDF: false, isImage: false };
      
      const extension = this.fileInfo.extension.toLowerCase();
      const isImage = ['jpg', 'jpeg', 'png'].includes(extension);
      return {
        isPDF: extension === 'pdf',
        isImage,
        mimeType: this.getMimeType(extension)
      };
    },
    originalFileName() {
      return this.fileInfo ? `${this.fileInfo.filename}.${this.fileInfo.extension}` : '';
    },
    contentStyle() {
      return {
        transform: `translate(${this.offsetX}px, ${this.offsetY}px) scale(${this.zoomLevel}) rotate(${this.rotation}deg)`,
        transition: this.isPanning ? 'none' : 'transform 0.3s ease',
        cursor: this.isPanning ? 'grabbing' : 'grab',
        transformOrigin: 'center'
      };
    },
    overlayStyle() {
      return {
        backgroundColor: `rgba(0, 0, 0, ${this.overlayOpacity})`
      };
    },
    isImageRotated() {
      return this.rotation !== 0 && this.rotation % 360 !== 0;
    },
    isHorizontalRotation() {
      return this.rotation === 90 || this.rotation === 270;
    },
    isImagePanned() {
      return this.offsetX !== 0 || this.offsetY !== 0;
    },
    isImageZoomed() {
      return this.zoomLevel !== 1;
    }
  },
  mounted() {
    document.addEventListener('keydown', this.handleKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeydown);
    this.cleanup();
  },
  methods: {
    handleLayoutChange(shouldHide = true) {
      const elementToHide = this.hideElementId ? document.getElementById(this.hideElementId) : null;
      const elementToExpand = this.expandElementId ? document.getElementById(this.expandElementId) : null;
      
      if (!elementToHide || !elementToExpand) return;
      
      if (shouldHide) {
        this.originalLayout = { elementToHide, elementToExpand, originalClass: elementToExpand.className };
        elementToExpand.classList.replace(this.originalElementClass, this.expandElementClass);
        elementToHide.style.display = 'none';
      } else if (this.originalLayout) {
        const { elementToHide, elementToExpand, originalClass } = this.originalLayout;
        elementToHide.style.display = '';
        elementToExpand.className = originalClass;
        this.originalLayout = null;
      }
    },

    async loadPreview() {
      try {
        this.isLoading = true;
        this.error = null;
        
        // Czyszczenie starej rotacji przed załadowaniem nowego pliku
        this.rotation = 0;
        this.initialScaleApplied = false;
        
        const response = await this.loadPreviewCallback();
        const blob = new Blob([response.data], { type: this.fileType.mimeType });
        this.fileUrl = URL.createObjectURL(blob);
        
        // Dla obrazów inicjalizujemy po załadowaniu
        if (this.fileType.isImage) {
          this.$nextTick(() => {
            this.initializeImage();
          });
        }
      } catch (error) {
        this.error = error.response?.status === 401
          ? 'Brak autoryzacji. Zaloguj się ponownie.'
          : 'Nie udało się załadować pliku';
        console.error('Preview error:', error);
      } finally {
        this.isLoading = false;
      }
    },

    initializeImage() {
      const imgElement = this.$el?.querySelector('.image-wrapper img');
      if (imgElement) {
        const updateDimensions = () => {
          this.naturalWidth = imgElement.naturalWidth;
          this.naturalHeight = imgElement.naturalHeight;
          this.originalOrientation = this.naturalWidth >= this.naturalHeight ? 'landscape' : 'portrait';
          
          // Zawsze zaczynamy od zoomu 1x
          this.zoomLevel = 1;
        };
        
        if (imgElement.complete) {
          updateDimensions();
        } else {
          imgElement.onload = updateDimensions;
        }
      }
    },

    showModal() {
      this.handleLayoutChange(true);
      this.isVisible = true;
      this.loadPreview();
      this.resetView();
    },

    hideModal() {
      this.isVisible = false;
      this.cleanup();
      this.handleLayoutChange(false);
      this.$emit('hidden');
    },

    handleMouseDown(event) {
      const canPan = event.button === 1 || 
                    (event.button === 0 && event.altKey) || 
                    (event.button === 0 && (this.isImageZoomed || this.isImageRotated));
      
      if (canPan) {
        event.preventDefault();
        this.isPanning = true;
        this.lastX = event.clientX;
        this.lastY = event.clientY;
      }
    },

    handleMouseMove(event) {
      if (!this.isPanning) return;

      const deltaX = event.clientX - this.lastX;
      const deltaY = event.clientY - this.lastY;

      this.offsetX += deltaX;
      this.offsetY += deltaY;

      this.lastX = event.clientX;
      this.lastY = event.clientY;
    },

    handleMouseUp(event) {
      if (event.button === 1 || event.button === 0) {
        this.isPanning = false;
      }
    },

    handleKeydown(event) {
      if (!this.isVisible) return;

      switch (event.key) {
        case 'Escape':
          this.hideModal();
          break;
        case 'r':
          this.resetView();
          break;
        case 'ArrowLeft':
          if (this.fileType.isImage) {
            this.rotateLeft();
            event.preventDefault();
          }
          break;
        case 'ArrowRight':
          if (this.fileType.isImage) {
            this.rotateRight();
            event.preventDefault();
          }
          break;
      }
    },

    resetView() {
      this.zoomLevel = 1;
      this.rotation = 0;
      this.offsetX = 0;
      this.offsetY = 0;
      this.isPanning = false;
      this.initialScaleApplied = false;
      this.userSelectedZoom = false;
      this.userZoomLevel = 1;
    },

    setZoomLevel(level) {
      const zoomLevels = {
        '1': 1,
        '1.5': 1.5,
        '2': 2,
        '3': 3
      };
      
      this.zoomLevel = zoomLevels[level] || 1;
      this.userSelectedZoom = true;
      this.userZoomLevel = this.zoomLevel;
      
      if (this.zoomLevel === 1) {
        this.offsetX = 0;
        this.offsetY = 0;
      }
    },

    rotateLeft() {
      // Obliczamy nową rotację
      this.rotation = (this.rotation - 90) % 360;
      if (this.rotation < 0) {
        this.rotation += 360;
      }
      
      // Sprawdzamy czy orientacja się zmieniła
      const isHorizontalRotation = this.rotation === 90 || this.rotation === 270;
      const currentOrientation = isHorizontalRotation ? 
        (this.originalOrientation === 'landscape' ? 'portrait' : 'landscape') : 
        this.originalOrientation;
      
      // Jeśli wracamy do oryginalnej orientacji i użytkownik wcześniej nie wybrał zooma
      if (currentOrientation === this.originalOrientation && !this.userSelectedZoom) {
        this.zoomLevel = 1; // Przywracamy do 1x
      } 
      // Jeśli zmieniamy orientację i użytkownik nie wybrał zooma
      else if (currentOrientation !== this.originalOrientation && !this.userSelectedZoom) {
        this.zoomLevel = 1.5; // Stosujemy domyślny zoom 1.5x
      } 
      // Jeśli użytkownik wybrał zoom, zostawiamy jego wybór
      else if (this.userSelectedZoom) {
        this.zoomLevel = this.userZoomLevel;
      }
      
      // Resetujemy offsety, aby wycentrować obraz
      this.offsetX = 0;
      this.offsetY = 0;
    },
    
    rotateRight() {
      // Obliczamy nową rotację
      this.rotation = (this.rotation + 90) % 360;
      
      // Sprawdzamy czy orientacja się zmieniła
      const isHorizontalRotation = this.rotation === 90 || this.rotation === 270;
      const currentOrientation = isHorizontalRotation ? 
        (this.originalOrientation === 'landscape' ? 'portrait' : 'landscape') : 
        this.originalOrientation;
      
      // Jeśli wracamy do oryginalnej orientacji i użytkownik wcześniej nie wybrał zooma
      if (currentOrientation === this.originalOrientation && !this.userSelectedZoom) {
        this.zoomLevel = 1; // Przywracamy do 1x
      } 
      // Jeśli zmieniamy orientację i użytkownik nie wybrał zooma
      else if (currentOrientation !== this.originalOrientation && !this.userSelectedZoom) {
        this.zoomLevel = 1.5; // Stosujemy domyślny zoom 1.5x
      } 
      // Jeśli użytkownik wybrał zoom, zostawiamy jego wybór
      else if (this.userSelectedZoom) {
        this.zoomLevel = this.userZoomLevel;
      }
      
      // Resetujemy offsety, aby wycentrować obraz
      this.offsetX = 0;
      this.offsetY = 0;
    },

    getMimeType(extension) {
      const mimeTypes = {
        pdf: 'application/pdf',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        png: 'image/png'
      };
      return mimeTypes[extension] || 'application/octet-stream';
    },

    cleanup() {
      if (this.fileUrl) {
        URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = null;
      }
      this.resetView();
      this.error = null;
    },

    openInNewTab() {
      if (this.fileUrl) {
        window.open(this.fileUrl, '_blank');
      }
    },

    async handleDownload() {
      try {
        const response = await this.downloadCallback();
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.originalFileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        URL.revokeObjectURL(url);
      } catch (error) {
        this.error = error.response?.status === 401
          ? 'Brak autoryzacji. Zaloguj się ponownie.'
          : 'Nie udało się pobrać pliku';
        console.error('Download error:', error);
      }
    },

    handleWheel(event) {
      if (this.isImageZoomed || this.isImageRotated) {
        event.preventDefault();
        
        const panFactor = 1;
        
        if (event.shiftKey) {
          this.offsetX -= event.deltaY * panFactor;
        } else {
          this.offsetX -= event.deltaX * panFactor;
          this.offsetY -= event.deltaY * panFactor;
        }
      }
    }
  }
};
</script>

<template>
  <div v-if="isVisible" class="custom-modal">
    <div class="custom-modal-overlay" :style="overlayStyle" @click="hideModal"></div>
    <div 
      class="custom-modal-container" 
      :class="{ 
        'position-left': position === 'left',
        'position-right': position === 'right',
        'position-center': position === 'center'
      }"
      :style="{ width: modalSize.width, height: modalSize.height }"
    >
      <header class="custom-modal-header">
        <div class="title-container">
          {{ title }}
          <i 
            :class="[
              'bx file-icon',
              {
                'bx-file-pdf': fileType.isPDF,
                'bx-image': fileType.isImage,
                'bx-file': !fileType.isPDF && !fileType.isImage
              }
            ]"
            :title="originalFileName"
          ></i>
        </div>
        
        <div class="actions-container">
          <div v-if="fileType.isImage" class="zoom-group">
            <button
              v-for="zoom in ['1', '1.5', '2', '3']"
              :key="zoom"
              class="btn btn-sm"
              :class="{ 
                'btn-primary': zoomLevel === +zoom, 
                'btn-light': zoomLevel !== +zoom 
              }"
              @click="setZoomLevel(zoom)"
              :title="zoom === '1' ? 'Rozmiar oryginalny' : `Powiększenie ${zoom}×`"
            >
              {{ zoom === '1' ? '1×' : zoom + '×' }}
            </button>
          </div>

          <div v-if="fileType.isImage" class="rotate-group">
            <button
              class="btn btn-icon rotate-btn"
              @click="rotateLeft"
              title="Obróć w lewo (strzałka w lewo)"
            >
              <i class="bx bx-rotate-left"></i>
            </button>
            <button
              class="btn btn-icon rotate-btn"
              @click="rotateRight"
              title="Obróć w prawo (strzałka w prawo)"
            >
              <i class="bx bx-rotate-right"></i>
            </button>
          </div>

          <div class="action-group" v-if="fileUrl">
            <button
              class="btn btn-icon"
              @click="resetView"
              title="Reset widoku (R)"
            >
              <i class="bx bx-reset"></i>
            </button>
            <button
              class="btn btn-icon"
              @click="handleDownload"
              title="Pobierz"
            >
              <i class="bx bx-download"></i>
            </button>
            <button
              class="btn btn-icon"
              @click="openInNewTab"
              title="Otwórz w nowej karcie"
            >
              <i class="bx bx-window-open"></i>
            </button>
            <button 
              class="btn btn-icon"
              @click="hideModal"
              title="Zamknij (ESC)"
            >
              <i class="bx bx-x"></i>
            </button>
          </div>
        </div>
      </header>

      <main 
        class="custom-modal-body"
        @mousedown="handleMouseDown"
        @mousemove="handleMouseMove"
        @mouseup="handleMouseUp"
        @mouseleave="isPanning = false"
        @wheel="handleWheel"
      >
        <div v-if="isLoading" class="text-center">
          <b-spinner variant="primary" label="Ładowanie..."></b-spinner>
          <p class="mt-2">Ładowanie podglądu...</p>
        </div>

        <div v-else-if="error" class="text-center text-danger">
          <i class="bx bx-error-circle font-size-24"></i>
          <p class="mt-2">{{ error }}</p>
        </div>

        <div v-else-if="fileUrl" class="preview-content">
          <div v-if="fileType.isPDF" class="pdf-container">
            <object
              :data="fileUrl"
              type="application/pdf"
              class="pdf-preview"
              :style="contentStyle"
            >
              <div class="text-center">
                <p>Nie można wyświetlić podglądu PDF.</p>
                <a :href="fileUrl" target="_blank" class="btn btn-sm btn-primary">
                  <i class="bx bx-window-open mr-1"></i>Otwórz w nowej karcie
                </a>
              </div>
            </object>
          </div>

          <div
            v-else-if="fileType.isImage"
            class="image-preview"
            :class="{ 
              'zoomed': isImageZoomed,
              'rotated': isImageRotated,
              'horizontal-rotation': isHorizontalRotation,
              'panned': isImagePanned
            }"
          >
            <img
              :src="fileUrl"
              :alt="originalFileName"
              :style="contentStyle"
              :class="{ 
                'cursor-zoom-in': !isImageZoomed, 
                'cursor-zoom-out': isImageZoomed,
                'cursor-grab': isImageZoomed || isImageRotated,
                'cursor-grabbing': isPanning
              }"
              @load="initializeImage"
            />
          </div>

          <div v-else class="text-center">
            <p>Podgląd tego typu pliku nie jest dostępny.</p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1050;
  padding: 2rem;

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1051;
    transition: background-color 0.3s ease;
  }

  &-container {
    position: relative;
    background-color: white;
    border-radius: 0.3rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    z-index: 1052;
    display: flex;
    flex-direction: column;
    margin-top: 2vh;

    &.position-left { margin-right: auto; }
    &.position-center { margin: auto; }
    &.position-right { margin-left: auto; }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 0.75rem;
    border-bottom: 1px solid #dee2e6;
    min-height: 40px;

    .title-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-size: 0.875rem;
      color: #495057;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      .file-icon {
        font-size: 1rem;
        color: inherit;
        cursor: help;
      }
    }

    .actions-container {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .zoom-group,
      .rotate-group,
      .action-group {
        display: flex;
        gap: 0.25rem;
      }

      .btn {
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
        line-height: 1.2;
        height: 24px;
        min-width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        &-icon {
          padding: 0;
          color: #6c757d;
          background: none;
          border: none;

          i {
            font-size: 1rem;
          }

          &:hover {
            color: #343a40;
          }
        }
      }

      .rotate-btn {
        &:focus, &:active {
          outline: none;
          box-shadow: none;
        }
      }
    }
  }

  &-body {
    flex: 1;
    overflow: hidden;
    padding: 0.75rem;
    position: relative;
    user-select: none;

    .preview-content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;

      .pdf-container {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .pdf-preview {
          width: 100%;
          height: 100%;
          border: 1px solid #dee2e6;
          border-radius: 0.25rem;
          transform-origin: center;
        }
      }

      .image-preview {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        position: relative;
        
        img {
          max-width: 95%;
          max-height: 95%;
          object-fit: contain;
          transform-origin: center;
          pointer-events: none;
          transition: transform 0.3s ease;
          
          &.cursor-zoom-in { cursor: zoom-in; }
          &.cursor-zoom-out { cursor: zoom-out; }
          &.cursor-grab { cursor: grab; }
          &.cursor-grabbing { cursor: grabbing; }
        }
        
        &.horizontal-rotation img {
          max-width: 85%;
          max-height: 85%;
        }
        
        &.zoomed img, &.panned img {
          pointer-events: auto;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .custom-modal {
    padding: 0.5rem;

    &-header {
      .actions-container {
        .zoom-group,
        .rotate-group {
          .btn {
            padding: 0.25rem;
            min-width: 20px;
          }
        }
      }
    }
  }
}
</style>
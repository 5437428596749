<script>
import ApplicationInvoiceAdd from "@/components/application/application-invoice-add";
import ApplicationInvoiceFraudCheck from "@/components/application/application-invoice-fraud-check";
import ApplicationItemAcceptance from "@/components/application/application-item-acceptance";
import ApplicationInvoiceAmount from "@/components/application/application-invoice-amount";
import ApplicationInvoiceNumber from "@/components/application/application-invoice-number";
import FilePreviewModal from "@/components/common/file-preview-modal";
import notificationMixin from '@/mixins/notificationMixin';
import Repository from "@/app/repository/repository-factory";
import EventBus from "@/event-bus";
import { UserType } from "@/constants/invoice-constants";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  name: "ApplicationInvoices",
  mixins: [notificationMixin],
  components: {
    ApplicationInvoiceAdd,
    ApplicationInvoiceFraudCheck,
    ApplicationItemAcceptance,
    ApplicationInvoiceAmount,
    ApplicationInvoiceNumber,
    FilePreviewModal
  },
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    },
    beneficiaryId: {
      type: String,
      required: true
    },
    invoices: {
      type: Array,
      required: true
    },
    canEditApplication: {
      type: Boolean,
      required: true
    },
    invoiceSumTotal: {
      type: Number,
      required: true
    },
    canChangeAmountOrAcceptance: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    invoicesCount() {
      return this.invoices.length;
    }
  },
  methods: {
    isInvoiceFromGuardian(invoice) {
      return invoice.userType === UserType.GUARDIAN;
    },
    isInvoiceFromFoundationEmployee(invoice) {
      return invoice.userType === UserType.FOUNDATION_EMPLOYEE;
    },
    showFilePreview(invoice) {
      this.$refs[`preview-modal-${invoice.invoiceId}`][0].showModal();
    },
    async handleInvoiceRemove(invoiceId) {
      try {
        const isConfirmed = await this.$swal({
          title: "Czy na pewno chcesz usunąć fakturę?",
          text: "Tej operacji nie można cofnąć!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak, usuń",
          cancelButtonText: "Anuluj"
        });
        if (!isConfirmed.value) {
          return;
        }
        await ApplicationApplicationRepository.removeInvoiceFromApplication(
          this.applicationId, 
          invoiceId, 
          this.applicationExpectedVersion
        );
        EventBus.$emit('applicationChanged', '');
        this.notifySuccess('Faktura została usunięta!');
      } catch (error) {
        this.notifyError(error.response?.data?.message || 'Nie udało się usunąć faktury');
      }
    },
    getPreviewCallback(invoiceId) {
      return () => ApplicationApplicationRepository.previewInvoiceFile(this.applicationId, invoiceId);
    },
    getDownloadCallback(invoiceId) {
      return () => ApplicationApplicationRepository.downloadInvoiceFile(this.applicationId, invoiceId);
    }
  }
};
</script>

<template>
  <div>
    <p class="card-title-desc">
      Dodaj wszystkie faktury przypisane do wniosku.
    </p>

    <application-invoice-add
      v-if="canEditApplication && applicationId"
      :application-id="applicationId"
      :application-expected-version="applicationExpectedVersion">
    </application-invoice-add>

    <div class="table-responsive">
      <table class="table mb-0">
        <thead class="thead-light">
          <tr>
            <th style="width: 50px;">Lp.</th>
            <th>Numer</th>
            <th style="min-width: 80px;">Data</th>
            <th>Kwota</th>
            <th>Dodał(a)</th>
            <th>Duble</th>
            <th style="width: 40px;" class="px-2">Weryfikacja</th>
            <th style="width: 10px;"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(invoice, index) in invoices" :key="invoice.invoiceId">
            <td class="text-center">{{ index + 1 }}</td>
            <td class="text-break">
              <div class="d-flex justify-content-between align-items-center">
                <application-invoice-number
                  :invoice="invoice"
                  :application-id="applicationId"
                  :can-edit-application="canChangeAmountOrAcceptance"
                />
                <i v-if="invoice.file"
                   class="bx bx-file preview-icon ml-2"
                   v-b-tooltip.hover
                   title="Podgląd"
                   @click="showFilePreview(invoice)"
                ></i>
              </div>
            </td>
            <td class="text-nowrap">{{ invoice.date }}</td>
            <td>
              <application-invoice-amount
                :invoice="invoice"
                :application-id="applicationId"
                :can-edit-application="canChangeAmountOrAcceptance" 
              />
            </td>
            <td class="text-break">
              <span 
                data-toggle="tooltip" 
                data-placement="top"
                :title="'Dodane: ' + invoice.createdAt"
              >
                <span 
                  v-if="isInvoiceFromGuardian(invoice)"
                  class="badge badge-soft-info mr-1"
                  v-b-tooltip.hover
                  title="Opiekun"
                >
                  O
                </span>
                <span 
                  v-if="isInvoiceFromFoundationEmployee(invoice)"
                  class="badge badge-soft-primary mr-1"
                  v-b-tooltip.hover
                  title="Pracownik fundacji"
                >
                  P
                </span>
                {{ invoice.createdBy }}
              </span>
            </td>
            <td>
              <application-invoice-fraud-check
                :invoice="invoice"
                :current-application-id="applicationId"
                :beneficiary-id="beneficiaryId"
              />
            </td>
            <td class="text-center px-2">
              <application-item-acceptance
                :item="invoice"
                :application-id="applicationId"
                :can-edit-application="canChangeAmountOrAcceptance"
                type="invoice"
              />
            </td>
            <td class="text-center">
              <div class="d-flex justify-content-center">
                <div v-b-tooltip.hover :title="isInvoiceFromFoundationEmployee(invoice) ? 'Usuń fakturę' : 'Można usuwać tylko faktury dodane przez pracownika fundacji'">
                  <button 
                    type="button" 
                    :disabled="!canEditApplication || isInvoiceFromGuardian(invoice)"
                    @click="handleInvoiceRemove(invoice.invoiceId)"
                    class="btn btn-sm btn-danger waves-effect waves-light"
                  >
                    <i class="bx bx-trash bx-fw"></i>
                  </button>
                </div>
                <file-preview-modal
                  :ref="`preview-modal-${invoice.invoiceId}`"
                  :load-preview-callback="getPreviewCallback(invoice.invoiceId)"
                  :download-callback="getDownloadCallback(invoice.invoiceId)"
                  :title="`Podgląd: ${invoice.number}`"
                  :file-info="invoice.file"
                  :modal-size="{ width: '41vw', height: '92vh' }"
                  position="right"
                  hide-element-id="sidebar"
                  expand-element-id="main-content"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th></th>
            <td style="text-align: right;" colspan="2">Suma faktur:</td>
            <td style="font-weight: bold; text-align: right; padding-right: 33px;">{{ (invoiceSumTotal || 0).toFixed(2) }} zł</td>
            <td colspan="4"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.verification-icon {
  font-size: 20px;
}

.preview-icon {
  cursor: pointer;
  font-size: 1rem;
  color: #495057;
  vertical-align: middle;
}

.preview-icon:hover {
  color: #000;
}

.d-flex > div {
  display: inline-block;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}

.btn-sm i {
  font-size: 1.1rem;
}
</style>
<script>
import ApplicationWithdrawalForm from "@/components/application/application-withdrawal-form";
import ApplicationAutomaticWithdrawalForm from "@/components/application/application-automatic-withdrawal-form";
import notificationMixin from '@/mixins/notificationMixin';

export default {
  name: "ApplicationWithdrawals",
  mixins: [notificationMixin],
  components: {
    ApplicationWithdrawalForm,
    ApplicationAutomaticWithdrawalForm
  },
  props: {
    settlement: {
      type: Object,
      required: true
    },
    canWithdrawal: {
      type: Boolean,
      required: true
    },
    applicationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      activeWithdrawalTab: 0 // Domyślnie aktywna zakładka automatycznych wypłat
    };
  },
  computed: {
    totalWithdrawalsAmount() {
      const withdrawals = this.settlement.withdrawals;
      return this.calculateTotalWithdrawals(withdrawals);
    },
    remainingAmount() {
      const totalToSettle = this.settlement.amountToSettled;
      const totalWithdrawn = this.totalWithdrawalsAmount;
      return this.calculateRemainingAmount(totalToSettle, totalWithdrawn);
    },
    isSettlementVisible() {
      return ['in_settlement', 'awaiting_settlement', 'partially_settled', 'settled'].includes(this.settlement.status);
    },
    canMakeWithdrawal() {
      return this.canWithdrawal && this.remainingAmount > 0;
    }
  },
  methods: {
    calculateTotalWithdrawals(withdrawals) {
      return withdrawals.reduce((total, withdrawal) => total + withdrawal.amount, 0);
    },
    calculateRemainingAmount(totalAmount, withdrawalsAmount) {
      return Number((totalAmount - withdrawalsAmount).toFixed(2));
    }
  }
};
</script>

<template>
  <div class="card" v-if="settlement && isSettlementVisible">
    <div class="card-body">

      <div v-if="canMakeWithdrawal" class="mb-4">
        <b-tabs v-model="activeWithdrawalTab" content-class="mt-3">
          <b-tab title="Wypłata automatyczna" active>
            <p class="text-muted">
              Wypłata automatyczna wybierze dostępne środki w optymalnej kolejności (najpierw konto 1,5%, potem konto imienne).
            </p>
            <application-automatic-withdrawal-form
              :settlement-id="settlement.settlementId"
              :settlement-expected-version="settlement.version"
              :application-id="applicationId"
              :settlement="settlement"
              :remaining-to-be-paid="remainingAmount">
            </application-automatic-withdrawal-form>
          </b-tab>
          <b-tab title="Wypłata ręczna">
            <p class="text-muted">
              Wypłata ręczna pozwala na wybór konkretnego konta i kwoty do wypłaty.
            </p>
            <application-withdrawal-form 
              :settlement-id="settlement.settlementId"
              :settlement-expected-version="settlement.version"
              :remaining-to-be-paid="remainingAmount">
            </application-withdrawal-form>
          </b-tab>
        </b-tabs>
      </div>

      <div class="table-responsive">
        <table class="table mb-0">
          <thead class="thead-light">
          <tr>
            <th>Data wypłaty</th>
            <th>Typ konta</th>
            <th>Kwota</th>
            <th>Wypłacił(a)</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="withdrawal in settlement.withdrawals" :key="withdrawal.withdrawalId">
            <th scope="row">{{ withdrawal.date }}</th>
            <td>{{ withdrawal.beneficiaryAccountType }}</td>
            <td>{{ withdrawal.amount.toFixed(2) }} zł</td>
            <td>
              <span 
                data-toggle="tooltip" 
                data-placement="top"
                :title="'Dodane: ' + withdrawal.createdAt"
              >
                {{ withdrawal.createdBy }}
              </span>
            </td>
          </tr>
          <tr>
            <th></th>
            <td style="text-align: right;">Suma wypłat:</td>
            <td style="font-weight: bold;">{{ totalWithdrawalsAmount.toFixed(2) }} zł</td>
            <td></td>
          </tr>
          <tr>
            <th></th>
            <td style="text-align: right;">Pozostało do wypłaty:</td>
            <td style="font-weight: bold;">{{ remainingAmount }} zł</td>
            <td></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template> 
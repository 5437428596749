<script>
import Repository from '@/app/repository/repository-factory';
import notificationMixin from '@/mixins/notificationMixin';
import EventBus from '@/event-bus';

const FraudCheckerRepository = Repository.get('FraudCheckerRepository');

export default {
  name: 'ApplicationInvoiceFraudCheck',
  mixins: [notificationMixin],
  
  props: {
    invoice: {
      type: Object,
      required: true
    },
    currentApplicationId: {
      type: String,
      required: true
    },
    beneficiaryId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      fraudData: null,
      showDetails: false,
      isChecked: false,
      isBusy: false
    };
  },

  computed: {
    // Czy znaleziono duplikaty
    hasDuplicate() {
      return this.fraudData?.hasDuplicate ?? false;
    },

    // Grupuje faktury według wniosków i zwraca tylko te, które mają duplikaty lub są w innych wnioskach
    invoiceGroups() {
      if (!this.fraudData?.duplicates?.invoices) return [];
      
      // Grupujemy faktury według ID wniosku
      const groups = {};
      this.fraudData.duplicates.invoices.forEach(invoice => {
        if (!invoice.usedByApplications) return;
        
        invoice.usedByApplications.forEach(applicationId => {
          if (!groups[applicationId]) {
            groups[applicationId] = [];
          }
          groups[applicationId].push({
            ...invoice,
            id: invoice.invoiceId, // mapujemy invoiceId na id dla kompatybilności
            number: invoice.number,
            date: invoice.date,
            amount: invoice.amount
          });
        });
      });
      
      // Zwracamy tylko wnioski z duplikatami lub inne wnioski
      return Object.entries(groups)
        .filter(([applicationId, invoices]) => 
          applicationId !== this.currentApplicationId || invoices.length > 1
        )
        .map(([applicationId, invoices]) => ({
          applicationId,
          invoices,
          isCurrentApplication: applicationId === this.currentApplicationId
        }));
    },

    // Generuje komunikat dla tooltipa
    tooltipMessage() {
      if (!this.fraudData?.hasDuplicate) {
        return 'Faktura nie została użyta w żadnym innym wniosku dla tego podopiecznego';
      }

      const invoice = this.fraudData.duplicates.invoices[0];
      const date = invoice.date;
      const amount = invoice.amount.toLocaleString('pl-PL') + ' zł';
      const count = this.fraudData.duplicates.invoices.length;
      
      return `Znaleziono ${count} ${count === 1 ? 'podobną fakturę' : 'podobne faktury'}\nData: ${date}\nKwota: ${amount} dla tego podopiecznego.\nKliknij po szczegóły.`;
    },

    // Generuje komunikat dla modalu
    modalMessage() {
      if (!this.fraudData?.hasDuplicate) return '';

      const invoice = this.fraudData.duplicates.invoices[0];
      const date = invoice.date;
      const amount = invoice.amount.toLocaleString('pl-PL') + ' zł';
      const count = this.fraudData.duplicates.invoices.length;
      
      return `Znaleziono ${count} ${count === 1 ? 'podobną fakturę' : 'podobne faktury'} dla tego podopiecznego:
• Data wystawienia: <strong>${date}</strong>
• Kwota: <strong>${amount}</strong>`;
    }
  },

  methods: {
    // Helpers do poprawnej odmiany słów
    getApplicationWord(count) {
      if (count === 1) return 'wniosku';
      if (count >= 2 && count <= 4) return 'wnioskach';
      return 'wnioskach';
    },

    getTimesWord(count) {
      if (count === 1) return 'raz';
      return 'razy';
    },

    // Sprawdza duplikaty faktury w systemie
    async checkFraud() {
      try {
        this.isBusy = true;
        const response = await FraudCheckerRepository.checkInvoiceDuplicate({
          date: this.invoice.date,
          amount: this.invoice.amount.toString(),
          beneficiaryId: this.beneficiaryId
        });
        
        this.fraudData = response.data;
        this.isChecked = true;
      } catch (error) {
        this.notifyError(error.response?.data?.message || 'Nie udało się sprawdzić duplikatów');
      } finally {
        this.isBusy = false;
      }
    },

    // Nawiguje do wybranego wniosku
    async goToApplication(applicationId) {
      try {
        // Najpierw zamykamy modal, potem nawigujemy
        this.showDetails = false;
        await this.$router.push({
          path: `/application/edit/${applicationId}`
        });
      } catch (error) {
        if (error.name !== 'NavigationDuplicated') {
          console.error('Błąd podczas nawigacji:', error);
          this.notifyError("Wystąpił błąd podczas przechodzenia do wniosku. Spróbuj ponownie");
        }
      }
    }
  },

  mounted() {
    this.checkFraud();
    EventBus.$on('applicationChanged', this.checkFraud);
  },

  beforeDestroy() {
    EventBus.$off('applicationChanged', this.checkFraud);
  }
};
</script>

<template>
  <div class="fraud-check-container">
    <!-- Spinner podczas ładowania -->
    <template v-if="isBusy">
      <b-spinner small variant="primary" role="status"></b-spinner>
    </template>

    <!-- Ikony statusu sprawdzenia -->
    <template v-else-if="isChecked">
      <i v-if="hasDuplicate" 
         class="bx bx-error-circle text-warning fraud-icon" 
         v-b-tooltip.hover.right
         :title="tooltipMessage"
         @click="showDetails = true">
      </i>
      <i v-else
         class="bx bx-check-circle text-success fraud-icon"
         v-b-tooltip.hover.right
         :title="tooltipMessage"
         style="cursor: default">
      </i>

      <!-- Modal ze szczegółami duplikatów -->
      <b-modal v-model="showDetails" 
               title="Uwaga! Wykryto potencjalne duplikaty faktury" 
               hide-footer 
               size="lg">
        <div v-if="hasDuplicate">
          <!-- Nagłówek z ostrzeżeniem -->
          <div class="alert alert-warning">
            <h5 class="alert-heading">
              <i class="bx bx-error-circle mr-2"></i>
              Uwaga! Wykryto potencjalne duplikaty faktury
            </h5>
            <div class="mt-2">
              <p class="mb-2">
                Znaleziono {{ fraudData.duplicates.invoices.length }} 
                {{ fraudData.duplicates.invoices.length === 1 ? 'podobną fakturę' : 'podobne faktury' }} 
                dla tego podopiecznego:
              </p>
              <div class="ml-3">
                <div class="d-flex align-items-center mb-1">
                  <i class="bx bx-calendar mr-2"></i>
                  <strong>Data wystawienia:</strong>
                  <span class="ml-2">{{ fraudData.duplicates.invoices[0].date }}</span>
                </div>
                <div class="d-flex align-items-center">
                  <i class="bx bx-money mr-2"></i>
                  <strong>Kwota:</strong>
                  <span class="ml-2">{{ fraudData.duplicates.invoices[0].amount.toLocaleString('pl-PL') }} zł</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Lista wniosków z duplikatami -->
          <div class="mt-4">
            <div v-for="(group, index) in invoiceGroups" 
                 :key="group.applicationId"
                 :class="{ 'mt-4': index > 0 }">
              <div class="d-flex justify-content-between align-items-center mb-2">
                <h5 class="mb-0">
                  <i class="bx bx-folder mr-2"></i>
                  {{ group.isCurrentApplication ? 'Ten wniosek' : 'Wniosek' }}
                  <small v-if="group.isCurrentApplication" class="text-muted">(aktualny)</small>
                </h5>
                <b-button 
                  v-if="!group.isCurrentApplication"
                  variant="primary"
                  size="sm"
                  @click="goToApplication(group.applicationId)"
                >
                  <i class="bx bx-edit mr-1"></i>
                  Przejdź do wniosku
                </b-button>
              </div>

              <!-- Lista faktur w danym wniosku -->
              <div class="list-group">
                <div v-for="duplicate in group.invoices" 
                     :key="duplicate.id" 
                     class="list-group-item">
                  <div class="d-flex justify-content-between align-items-center">
                    <div>
                      <i class="bx bx-file mr-2"></i>
                      Faktura nr: <strong>{{ duplicate.number }}</strong>
                    </div>
                    <div class="text-muted">
                      <small>Data: {{ duplicate.date }} | Kwota: {{ duplicate.amount }} zł</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </template>
  </div>
</template>

<style scoped>
.fraud-check-container {
  display: inline-block;
}

.fraud-icon {
  font-size: 1.5rem;
  cursor: pointer;
}

.fraud-icon:hover {
  opacity: 0.8;
}

.list-group-item {
  transition: background-color 0.2s;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}
</style> 
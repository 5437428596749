<script>
import EventBus from "@/event-bus";
import { InvoiceAcceptanceStatus, InvoiceAcceptanceStatusLabels, UserType } from "@/constants/invoice-constants";
import Repository from "@/app/repository/repository-factory";
import NotificationMixin from "@/mixins/notificationMixin";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  name: "ApplicationItemAcceptance",
  mixins: [NotificationMixin],
  components: {
    TooltipWrapper: {
      functional: true,
      props: {
        tooltipContent: {
          type: String,
          default: ''
        }
      },
      render(h, { props, children }) {
        if (props.tooltipContent) {
          return h(
            'span',
            {
              directives: [
                {
                  name: 'b-tooltip',
                  value: props.tooltipContent,
                  modifiers: { html: true, hover: true, right: true}
                }
              ],
              staticClass: 'flex-fill d-flex justify-content-center align-items-center'
            },
            children
          );
        } else {
          return h(
            'span',
            {
              staticClass: 'flex-fill d-flex justify-content-center align-items-center'
            },
            children
          );
        }
      }
    }
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    applicationId: {
      type: String,
      required: true
    },
    canEditApplication: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      required: true,
      validator: function(value) {
        return ['invoice', 'trip'].indexOf(value) !== -1
      }
    }
  },
  data() {
    return {
      rejectionReason: ''
    };
  },
  computed: {
    acceptance() {
      return this.item.acceptance || null;
    },
    modalId() {
      return `modal-reject-${this.type}-${this.item[this.type === 'invoice' ? 'invoiceId' : 'tripId']}`;
    },
    isReasonValid() {
      return this.rejectionReason.length >= 8;
    },
    showThreeStates() {
      return !this.acceptance;
    },
    sliderClasses() {
      return {
        'position-left': this.isItemAccepted(),
        'position-right': this.isItemRejected(),
        'position-middle': this.showThreeStates,
        'bg-success': this.isItemAccepted(),
        'bg-danger': this.isItemRejected(),
        'bg-warning': this.showThreeStates
      };
    },
    currentSliderIcon() {
      if (this.isItemAccepted()) return 'bx-check';
      if (this.isItemRejected()) return 'bx-x';
      return 'bx-help-circle';
    },
    computedAcceptTooltip() {
      const itemType = this.type === 'invoice' ? 'fakturę' : 'przejazd';
      if (this.canEditApplication) {
        return !this.isItemAccepted()
          ? `Kliknij aby zaakceptować ${itemType}`
          : `Zaakceptowana przez: ${this.acceptance.decidedBy}<br>Data: ${this.formatDate(this.acceptance.decidedAt)}`;
      } else {
        return this.acceptance && this.acceptance.status === InvoiceAcceptanceStatus.ACCEPTED
          ? `Zaakceptowana przez: ${this.acceptance.decidedBy}<br>Data: ${this.formatDate(this.acceptance.decidedAt)}`
          : '';
      }
    },
    computedRejectTooltip() {
      const itemType = this.type === 'invoice' ? 'fakturę' : 'przejazd';
      if (this.canEditApplication) {
        return !this.isItemRejected()
          ? `Kliknij aby odrzucić ${itemType}`
          : `Odrzucona przez: ${this.acceptance.decidedBy}<br>Data: ${this.formatDate(this.acceptance.decidedAt)}<br>Powód: ${this.acceptance.rejectionReason}`;
      } else {
        return this.acceptance && this.acceptance.status === InvoiceAcceptanceStatus.REJECTED
          ? `Odrzucona przez: ${this.acceptance.decidedBy}<br>Data: ${this.formatDate(this.acceptance.decidedAt)}<br>Powód: ${this.acceptance.rejectionReason}`
          : '';
      }
    },
    computedStatusTooltip() {
      if (!this.canEditApplication) {
        const itemType = this.type === 'invoice' ? 'faktury' : 'przejazdu';
        if (this.isItemFromGuardian()) {
          return `Akceptacja ${itemType} jest możliwa tylko w statusach "Złożony przez opiekuna" lub "Korekta"`;
        }
        return `Akceptacja ${itemType} jest możliwa tylko w statusach "W trakcie przygotowania", "Złożony przez opiekuna" lub "Korekta"`;
      }
      return this.canEditApplication ? this.getStatusTooltip() : '';
    }
  },
  methods: {
    isItemAccepted() {
      return this.acceptance?.status === InvoiceAcceptanceStatus.ACCEPTED;
    },
    isItemRejected() {
      return this.acceptance?.status === InvoiceAcceptanceStatus.REJECTED;
    },
    isItemFromGuardian() {
      return this.item.userType === UserType.GUARDIAN;
    },
    getStatusClass() {
      if (!this.acceptance) return 'text-secondary';
      return {
        [InvoiceAcceptanceStatus.ACCEPTED]: 'text-success',
        [InvoiceAcceptanceStatus.REJECTED]: 'text-danger'
      }[this.acceptance.status] || 'text-secondary';
    },
    getStatusIcon() {
      if (!this.acceptance) return 'bx-help-circle';
      return {
        [InvoiceAcceptanceStatus.ACCEPTED]: 'bx-check',
        [InvoiceAcceptanceStatus.REJECTED]: 'bx-x'
      }[this.acceptance.status] || 'bx-help-circle';
    },
    getStatusTooltip() {
      if (!this.acceptance) {
        return `${this.type === 'invoice' ? 'Faktura' : 'Przejazd'} wymaga weryfikacji - proszę zaakceptować lub odrzucić`;
      }
      const status = InvoiceAcceptanceStatusLabels[this.acceptance.status];
      const decisionBy = this.acceptance.decidedBy;
      const decisionDate = this.formatDate(this.acceptance.decidedAt);
      if (this.acceptance?.status === InvoiceAcceptanceStatus.REJECTED) {
        return `${status} przez: ${decisionBy}<br>
                Data: ${decisionDate}<br>
                Powód: ${this.acceptance.rejectionReason}`;
      }
      return `${status} przez: ${decisionBy}<br>Data: ${decisionDate}`;
    },
    getDecisionInfo() {
      if (!this.acceptance?.decidedBy) return '';
      return `${this.acceptance.decidedBy} dnia ${this.formatDate(this.acceptance.decidedAt)}`;
    },
    async handleItemAccept() {
      if (this.isItemAccepted()) return;
      try {
        if (this.type === 'invoice') {
          await ApplicationApplicationRepository.acceptInvoice(
            this.applicationId,
            this.item.invoiceId
          );
          EventBus.$emit('applicationChanged', '');
        } else {
          await ApplicationApplicationRepository.acceptTrip(
            this.applicationId,
            this.item.tripId
          );
          EventBus.$emit('applicationChanged', '');
        }
        this.$set(this.item, 'acceptance', {
          status: InvoiceAcceptanceStatus.ACCEPTED,
          decidedBy: "Aktualny użytkownik",
          decidedAt: new Date()
        });
        this.notifySuccess(`${this.type === 'invoice' ? 'Faktura' : 'Przejazd'} została zaakceptowana!`);
      } catch (error) {
        this.notifyError(error.response?.data?.message || 'Spróbuj ponownie później');
      }
    },
    async handleItemReject() {
      if (this.isItemRejected() || !this.isReasonValid) return;
      try {
        if (this.type === 'invoice') {
          await ApplicationApplicationRepository.rejectInvoice(
            this.applicationId,
            this.item.invoiceId,
            { reason: this.rejectionReason }
          );
        } else {
          await ApplicationApplicationRepository.rejectTrip(
            this.applicationId,
            this.item.tripId,
            { reason: this.rejectionReason }
          );
        }
        this.$set(this.item, 'acceptance', {
          status: InvoiceAcceptanceStatus.REJECTED,
          decidedBy: "Aktualny użytkownik",
          decidedAt: new Date(),
          rejectionReason: this.rejectionReason
        });
        this.$bvModal.hide(this.modalId);
        this.handleSuccessfulAction('odrzucona');
      } catch (error) {
        this.handleActionError(error);
      }
    },
    showRejectModal() {
      if (this.isItemRejected()) return;
      this.rejectionReason = '';
      this.$bvModal.show(this.modalId);
    },
    handleSuccessfulAction(action) {
      EventBus.$emit('applicationChanged', '');
      this.notifySuccess(`${this.type === 'invoice' ? 'Faktura' : 'Przejazd'} została ${action}!`);
    },
    handleActionError(error) {
      const errorMsg = error?.response?.data?.message || 'Wystąpił nieoczekiwany błąd!';
      this.notifyError(errorMsg);
    },
    formatDate(date) {
      if (!date) return '';
      return new Date(date).toLocaleString('pl-PL', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  }
};
</script>

<template>
  <div class="verification-container">
    <!-- Element od opiekuna -->
    <template v-if="isItemFromGuardian()">
      <div class="verification-switch-container">
        <!-- Zawsze wyświetlamy przełącznik, a przyciski są dezaktywowane, gdy nie można edytować -->
        <div class="verification-switch" :class="{ 'three-states': showThreeStates, 'disabled': !canEditApplication }">
          <TooltipWrapper :tooltip-content="!canEditApplication ? computedStatusTooltip : computedAcceptTooltip">
            <button 
              class="switch-option" 
              :class="{ 'active': isItemAccepted() }"
              :disabled="!canEditApplication"
              @click="handleItemAccept"
            >
              <i class="bx bx-check"></i>
            </button>
          </TooltipWrapper>
          
          <!-- Środkowy przycisk (widoczny tylko gdy showThreeStates) -->
          <template v-if="showThreeStates">
            <TooltipWrapper :tooltip-content="computedStatusTooltip">
              <button 
                class="switch-option switch-option-middle"
                :disabled="!canEditApplication"
              >
                <span class="invisible">
                  <i class="bx bx-help-circle"></i>
                </span>
              </button>
            </TooltipWrapper>
          </template>

          <TooltipWrapper :tooltip-content="!canEditApplication ? computedStatusTooltip : computedRejectTooltip">
            <button 
              class="switch-option" 
              :class="{ 'active': isItemRejected() }"
              :disabled="!canEditApplication"
              @click="showRejectModal"
            >
              <i class="bx bx-x"></i>
            </button>
          </TooltipWrapper>

          <div 
            class="switch-slider" 
            :class="sliderClasses"
          >
            <i 
              v-if="isItemAccepted()"
              class="bx bx-check slider-icon"
            ></i>
            <i 
              v-else-if="isItemRejected()"
              class="bx bx-x slider-icon"
            ></i>
            <i 
              v-else
              class="bx bx-help-circle slider-icon"
            ></i>
          </div>
        </div>
      </div>
    </template>

    <!-- Modal do odrzucania -->
    <b-modal 
      :id="modalId" 
      :title="`Odrzuć ${type === 'invoice' ? 'fakturę' : 'przejazd'}`"
      @ok="handleItemReject"
      ok-title="Odrzuć"
      cancel-title="Anuluj"
      ok-variant="danger"
      :ok-disabled="!isReasonValid"
    >
      <div class="form-group">
        <label>Powód odrzucenia</label>
        <textarea 
          v-model="rejectionReason"
          class="form-control" 
          rows="3"
          placeholder="Podaj powód odrzucenia..."
        ></textarea>
        <small 
          v-if="!isReasonValid" 
          class="text-danger"
        >
          Powód odrzucenia musi zawierać minimum 8 znaków
        </small>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.verification-container {
  min-width: unset;
}

.verification-icon {
  font-size: 18px;
}

.verification-switch-container {
  padding: 2px;
}

.verification-switch {
  position: relative;
  display: flex;
  background: #e9ecef;
  border-radius: 16px;
  padding: 1px;
  width: 70px;
  height: 26px;
  transition: all 0.3s ease;
}

.verification-switch.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.verification-switch.disabled .switch-slider {
  opacity: 0.6;
}

.verification-switch.disabled .switch-option {
  cursor: not-allowed;
}

.verification-switch.three-states {
  width: 90px;
}

/* Dodajemy style dla wyłączonego komponentu */
.verification-switch button:disabled ~ .switch-slider {
  opacity: 0.6;
  cursor: not-allowed;
}

.verification-switch button:disabled ~ .switch-slider i {
  opacity: 0.6;
}

.switch-option {
  position: relative;
  z-index: 1;
  flex: 1;
  border: none;
  background: none;
  cursor: pointer;
  padding: 2px;
  transition: all 0.3s ease;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
}

.switch-option:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.switch-option.active {
  color: white;
}

.switch-option i {
  font-size: 14px;
}

.switch-slider {
  position: absolute;
  top: 1px;
  left: 1px;
  width: calc(50% - 1px);
  height: calc(100% - 2px);
  border-radius: 12px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slider-icon {
  font-size: 16px;
  color: #ffffff;
  transition: opacity 0.3s ease;
}

.switch-slider.position-left {
  transform: translateX(0);
}

.switch-slider.position-right {
  transform: translateX(100%);
}

.switch-slider.position-middle {
  transform: translateX(100%);
  background-color: #fd7e14 !important;
}

.three-states .switch-slider {
  width: calc(33.33% - 1px);
}

.three-states .switch-slider.position-right {
  transform: translateX(200%);
}

.bg-success {
  background-color: #34c38f !important;
}

.bg-danger {
  background-color: #f46a6a !important;
}

.bg-warning {
  background-color: #fd7e14 !important;
}

.switch-option-middle {
  color: #fd7e14;
}

.invisible {
  visibility: hidden;
}
</style> 
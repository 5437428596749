<script>
export default {
  name: 'Preloader',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    scrollPosition: {
      type: Number,
      default: 0
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (!newValue && oldValue) {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollPosition);
        });
      }
    }
  }
}
</script>

<template>
  <div v-if="value" class="preloader-overlay">
    <div class="spinner-container">
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.preloader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
}

.spinner {
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin: 0 2px;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  animation-delay: -1.1s;
}

.spinner .rect3 {
  animation-delay: -1.0s;
}

.spinner .rect4 {
  animation-delay: -0.9s;
}

.spinner .rect5 {
  animation-delay: -0.8s;
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
  }  
  20% { 
    transform: scaleY(1.0);
  }
}
</style>

<script>
import ApplicationChangeNumber from "@/components/application/application-change-number";
import notificationMixin from '@/mixins/notificationMixin';
import { UserType } from "@/constants/invoice-constants";

export default {
  name: "ApplicationDetails",
  mixins: [notificationMixin],
  components: {
    ApplicationChangeNumber
  },
  props: {
    application: {
      type: Object,
      required: true
    },
    beneficiary: {
      type: Object,
      required: true
    },
    isNumberEdition: {
      type: Boolean,
      default: false
    },
    isEditable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isApplicationFromFoundationEmployee() {
      return this.application.userType === UserType.FOUNDATION_EMPLOYEE;
    }
  }
};
</script>

<template>
  <div>
    <!-- Dane wniosku -->
    <div class="card">
      <div class="card-body">
        <h4 v-if="application">
          <i class="bx bx-clipboard mr-1"></i>
          Nr: {{ application.number }}
          <i class="bx bx-edit mr-1 small" @click="$emit('edit-number')" title="Zmień numer wniosku"></i>
        </h4>
        <application-change-number
          v-if="isNumberEdition && application"
          :application-id="application.applicationId"
          :full-number="application.number">
        </application-change-number>
        <hr>
        <h4 v-if="beneficiary && beneficiary.beneficiaryId">
          <i class="bx bx-user-circle mr-1"></i>
          <router-link :to="{ name: 'Profil podopiecznego', params: { id: beneficiary.beneficiaryId } }">
            {{ beneficiary.lastName }} {{ beneficiary.firstName }}
          </router-link>
        </h4>
        <h4 v-else-if="beneficiary">
          <i class="bx bx-user mr-1"></i>
          {{ beneficiary.lastName }} {{ beneficiary.firstName }}
        </h4>
        <hr>
        <h4 v-if="application">
          <i class="bx bx-calendar mr-2" style="vertical-align: middle;"></i>
          <span style="vertical-align: middle;">{{ application.date }}</span>
        </h4>
        <h5 v-if="application" class="mt-2 creator-info">
          <i class="bx bx-user mr-2" style="vertical-align: middle;"></i>
          <span class="creator-name">{{ application.createdBy }}</span>
          <span 
            v-b-tooltip.html.hover 
            :title="`Rola: ${isApplicationFromFoundationEmployee ? 'Pracownik fundacji' : 'Opiekun'}<br>Data utworzenia: ${application.createdAt}`" 
            class="ml-2" 
            style="display: inline-block; vertical-align: middle;"
          >
            <i class="bx bx-info-circle" style="font-size: 0.8em;"></i>
          </span>
          <span class="badge ml-2" :class="isApplicationFromFoundationEmployee ? 'badge-primary' : 'badge-info'">
            {{ isApplicationFromFoundationEmployee ? 'Fundacja' : 'Opiekun' }}
          </span>
        </h5>
        <hr>
        <h3 v-if="application && application.amountToBePaid !== undefined" style="font-size: 1.8em;">
          <i class="bx bx-wallet mr-2" style="vertical-align: middle;"></i>
          <span style="vertical-align: middle;">{{ Number(application.amountToBePaid).toFixed(2) }} zł</span>
          <i class="bx bx-info-circle ml-2" 
             v-b-tooltip.hover.html="'Kwota do wypłaty obejmuje zatwierdzone faktury oraz przejazdy z karty drogowej.'" 
             style="vertical-align: middle; cursor: pointer; font-size: 0.7em;"></i>
        </h3>
        <hr>
        <b-button 
          v-if="isEditable && isApplicationFromFoundationEmployee" 
          variant="danger" 
          class="delete-button"
          @click="$emit('remove-application')"
        >
          <i class="bx bx-trash mr-1"></i>
          Usuń wniosek
        </b-button>
      </div>
    </div>
  </div>
</template>

<style scoped>
.delete-button {
  width: 100%;
  padding: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete-button i {
  font-size: 1.2em;
}

.creator-info {
  display: flex;
  align-items: center;
}

.creator-name {
  font-size: 0.8em;
  font-weight: 500;
}

.badge {
  font-size: 0.7em;
  padding: 5px 10px;
  border-radius: 12px;
}

.badge-primary {
  background-color: #556ee6;
  color: white;
}

.badge-info {
  background-color: #50a5f1;
  color: white;
}
</style> 
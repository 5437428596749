<script>
const greaterThanZero = (value) => value > 0;
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import EventBus from '../../event-bus'
import Repository from "../../app/repository/repository-factory";
import {required} from "vuelidate/lib/validators";
import NotificationMixin from "@/mixins/notificationMixin";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  components: {DatePicker},
  mixins: [NotificationMixin],
  props: {
    applicationId: {
      type: String,
      required: true
    },
    fullNumber: {
      type: Number,
      required: true
    },
  },
  data() {
    return {

      isBusy: false,
      submitted: false,
      form: {
        number: this.number,
        year: this.year
      },
    };
  },
  validations: {
    form: {
      number: {required, greaterThanZero},
      year: {required},
    }
  },
  created() {
    let strArray = this.fullNumber.split("/");
    this.form.year = strArray[0];
    this.form.number = strArray[1];
    console.log(this.fullNumber);
  },
  methods: {
    async createApplication() {
      this.formSubmit();
      if (this.$v.form.$error) {
        return false;
      }
      const payload = { number: this.form.number, year: this.form.year };
      try {
        await ApplicationApplicationRepository.changeNumber(this.applicationId, payload);
        EventBus.$emit("applicationNumberChanged", "");
        this.notifySuccess("Numer wniosku został zaktualizowany!", { timer: 500 });
        this.inProgress = false;
      } catch (error) {
        this.notifyError(error.response.data.message);
        this.inProgress = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.form.$touch()
    }
  }
};
</script>
<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-4 col-lg-4">
        <div class="form-group">
          <date-picker
            id="year"
            type="year"
            format="YYYY"
            v-model="form.year"
            value-type="format"
            :class="{ 'is-invalid': submitted && $v.form.year.$error }"
            lang="pl">
          </date-picker>
          <div v-if="submitted && $v.form.year.$error" class="invalid-feedback">
            <span v-if="!$v.form.year.required">Ta wartość jest wymagana.<br></span>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-lg-5">
        <div class="form-group">
          <input
            id="number"
            v-model="form.number"
            type="text"
            class="form-control"
            placeholder="Numer wniosku"
            value=""
            :class="{ 'is-invalid': submitted && $v.form.number.$error }"
          />
          <div v-if="submitted && $v.form.number.$error" class="invalid-feedback">
            <span v-if="!$v.form.number.required">Ta wartość jest wymagana.</span>
            <span v-if="!$v.form.number.greaterThanZero">Wartość musi być większa od 0.</span>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="float-sm-left">
          <button type="button" v-on:click="createApplication"
                  class="btn btn-primary waves-effect waves-light">Zmień
            <template v-if="isBusy">
              <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
            </template>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

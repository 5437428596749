/**
 * @enum {string}
 */
export const InvoiceAcceptanceStatus = {
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  PENDING: 'pending'
};

/**
 * @enum {string}
 */
export const UserType = {
  GUARDIAN: 'GUARDIAN',
  FOUNDATION_EMPLOYEE: 'FOUNDATION_EMPLOYEE'
};

export const InvoiceAcceptanceStatusLabels = {
  [InvoiceAcceptanceStatus.ACCEPTED]: 'Zaakceptowana',
  [InvoiceAcceptanceStatus.REJECTED]: 'Odrzucona',
  [InvoiceAcceptanceStatus.PENDING]: 'Oczekuje'
};

export const UserTypeLabels = {
  [UserType.GUARDIAN]: 'Opiekun',
  [UserType.FOUNDATION_EMPLOYEE]: 'Pracownik fundacji'
}; 
<script>
import Repository from "@/app/repository/repository-factory";
import { required } from "vuelidate/lib/validators";
import EventBus from "@/event-bus";
import NotificationMixin from "@/mixins/notificationMixin";

const AdditionalDocumentsRepository = Repository.get("AdditionalDocumentsRepository");

export default {
  name: "ApplicationAdditionalDocumentAdd",
  mixins: [NotificationMixin],
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isBusy: false,
      submitted: false,
      maxFileSize: 9 * 1024 * 1024, // 9MB w bajtach
      form: {
        name: '',
        file: null
      }
    };
  },
  validations: {
    form: {
      name: { required },
      file: { 
        required,
        maxSize(file) {
          if (!file) return true;
          return file.size <= this.maxFileSize;
        }
      }
    }
  },
  methods: {
    async addDocument() {
      this.formSubmit();
      if (this.$v.form.$error) {
        return;
      }

      this.isBusy = true;
      const formData = new FormData();
      formData.append('name', this.form.name);
      formData.append('file', this.form.file);

      try {
        await AdditionalDocumentsRepository.addDocument(this.applicationId, formData);
        // Najpierw odśwież listę dokumentów
        EventBus.$emit('refreshDocuments');
        // Potem powiadom o zmianie w aplikacji
        EventBus.$emit('applicationChanged');
        this.notifySuccess("Dokument został dodany!", { timer: 1500 });
        this.resetForm();
      } catch (error) {
        this.notifyError(error.response?.data?.message || "Wystąpił błąd podczas dodawania dokumentu");
      } finally {
        this.isBusy = false;
        this.submitted = false;
      }
    },

    resetForm() {
      this.form.name = '';
      this.form.file = null;
      this.$v.form.$reset();
      this.submitted = false;
    },

    formSubmit() {
      this.submitted = true;
      this.$v.form.$touch();
    }
  }
};
</script>

<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-4 pr-md-2">
        <div class="form-group">
          <div class="input-group">
            <b-form-file
              v-model="form.file"
              placeholder="Wybierz plik..."
              drop-placeholder="Przeciągnij plik tutaj!"
              accept=".pdf,.jpg,.jpeg,.png"
              :size="String(maxFileSize)"
              @input="$v.form.file.$touch()"
              :class="{ 'is-invalid': submitted && $v.form.file.$error }"
            ></b-form-file>
            <div class="input-group-append">
              <span 
                class="input-group-text text-info"
                v-b-tooltip.hover="{ title: 'Załącznik jest opcjonalny. Obsługiwane pliki: .pdf, .jpg, .jpeg, .png. Maksymalny rozmiar: ' + (maxFileSize / (1024 * 1024)) + ' MB' }"
              >
                <i class="bx bx-info-circle"></i>
              </span>
            </div>
          </div>
          <div v-if="submitted && $v.form.file.$error" class="invalid-feedback d-block">
            <span v-if="!$v.form.file.required">Załącznik jest wymagany</span>
            <span v-if="!$v.form.file.maxSize">
              Plik jest zbyt duży. Maksymalny dozwolony rozmiar to {{ (maxFileSize / (1024 * 1024))  }} MB.
            </span>
          </div>
        </div>
      </div>

      <div class="col-md-5 px-md-2">
        <div class="form-group">
          <input
            v-model="form.name"
            type="text"
            class="form-control"
            placeholder="Nazwa dokumentu"
            :class="{ 'is-invalid': submitted && $v.form.name.$error }"
          />
          <div v-if="submitted && $v.form.name.$error" class="invalid-feedback">
            <span v-if="!$v.form.name.required">Nazwa dokumentu jest wymagana</span>
          </div>
        </div>
      </div>

      <div class="col-md-3 pl-md-2">
        <button 
          type="button" 
          @click="addDocument"
          class="btn btn-primary waves-effect waves-light w-100"
          :disabled="isBusy"
        >
          Dodaj dokument
          <b-spinner v-if="isBusy" small class="ml-2" variant="light"></b-spinner>
        </button>
      </div>
    </div>
  </form>
</template>

<style scoped>
/* Wyświetlanie komunikatów błędów */
.invalid-feedback {
  display: block !important;
}

/* Czerwona ramka dla nieprawidłowego pola */
.is-invalid {
  border-color: #dc3545;
}

/* Ukrycie przycisku "Przeglądaj" */
::v-deep .custom-file-label::after {
  display: none;
}

/* Dostosowanie szerokości pola bez przycisku */
::v-deep .custom-file-label {
  padding-right: 0.75rem;
  border-radius: 0.25rem;
}

/* Zmniejszenie marginesów w formularzu */
.form-group {
  margin-bottom: 0.5rem;
}

/* Zmniejszenie paddingu w input-group */
.input-group {
  margin-bottom: 0;
}

/* Responsywne marginesy */
@media (max-width: 768px) {
  [class*="col-"] {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  
  .form-group {
    margin-bottom: 0.25rem;
  }
}
</style> 
<script>
import ApplicationTripAdd from "./application-trip-add.vue";
import ApplicationTripKilometers from "./application-trip-kilometers.vue";
import FilePreviewModal from "@/components/common/file-preview-modal";
import notificationMixin from '@/mixins/notificationMixin';
import Repository from "@/app/repository/repository-factory";
import { UserType } from "@/constants/invoice-constants";
import EventBus from "@/event-bus";
import ApplicationItemAcceptance from "./application-item-acceptance.vue";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  name: "ApplicationTrips",
  mixins: [notificationMixin],
  components: {
    ApplicationTripAdd,
    ApplicationTripKilometers,
    ApplicationItemAcceptance,
    FilePreviewModal
  },
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    },
    trips: {
      type: Array,
      required: true
    },
    canEditApplication: {
      type: Boolean,
      required: true
    },
    canChangeAmountOrAcceptance: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    tripsCount() {
      return this.trips?.length || 0;
    },
    totalKilometers() {
      return this.trips?.reduce((sum, trip) => sum + parseFloat(trip.kilometers || 0), 0) || 0;
    },
    totalCompensation() {
      return this.trips?.reduce((sum, trip) => sum + parseFloat(trip.compensationAmount || 0), 0) || 0;
    }
  },
  data() {
    return {
      commentModalVisible: false,
      selectedTripComment: null
    }
  },
  methods: {
    isTripFromFoundationEmployee(trip) {
      return trip.userType === UserType.FOUNDATION_EMPLOYEE;
    },
    isTripFromGuardian(trip) {
      return trip.userType === UserType.GUARDIAN;
    },
    hasComment(trip) {
      return trip && trip.comment && trip.comment.trim().length > 0;
    },
    showCommentModal(trip) {
      if (this.hasComment(trip)) {
        this.selectedTripComment = trip;
        this.commentModalVisible = true;
      }
    },
    hideCommentModal() {
      this.commentModalVisible = false;
      this.selectedTripComment = null;
    },
    async handleTripRemove(tripId) {
      try {
        const isConfirmed = await this.$swal({
          title: "Czy na pewno chcesz usunąć przejazd?",
          text: "Tej operacji nie można cofnąć!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak, usuń",
          cancelButtonText: "Anuluj"
        });
        if (!isConfirmed.value) {
          return;
        }
        await ApplicationApplicationRepository.removeTripFromApplication(this.applicationId, tripId);
        EventBus.$emit('applicationChanged', '');
        this.notifySuccess('Przejazd został usunięty!');
      } catch (error) {
        this.notifyError(error.response?.data?.message || 'Nie udało się usunąć przejazdu');
      }
    },
    showFilePreview(trip) {
      this.$refs[`preview-modal-${trip.tripId}`][0].showModal();
    },
    getPreviewCallback(tripId) {
      return () => ApplicationApplicationRepository.previewTripFile(this.applicationId, tripId);
    },
    getDownloadCallback(tripId) {
      return () => ApplicationApplicationRepository.downloadTripFile(this.applicationId, tripId);
    }
  }
};
</script>

<template>
  <div>
    <p class="card-title-desc">
      Dodaj przejazdy do wniosku.
    </p>

    <application-trip-add
      v-if="canEditApplication"
      :application-id="applicationId"
      :application-expected-version="applicationExpectedVersion"
      :require-attachment="false">
    </application-trip-add>

    <div class="table-responsive">
      <table class="table mb-0">
        <thead class="thead-light">
          <tr>
            <th>Lp.</th>
            <th>Skąd</th>
            <th>Dokąd</th>
            <th>Data</th>
            <th>Kilometry</th>
            <th>Kwota zwrotu</th>
            <th>Dodał(a)</th>
            <th style="width: 0%; white-space: nowrap;">Weryfikacja</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(trip, index) in trips" :key="trip.tripId">
            <td>{{ index + 1 }}</td>
            <td>{{ trip.startLocation }}</td>
            <td style="font-weight: 600;">
              {{ trip.endLocation }}
              <i v-if="trip.file"
                 class="bx bx-file preview-icon ml-2"
                 v-b-tooltip.hover
                 title="Podgląd"
                 @click="showFilePreview(trip)"
              ></i>
              <i v-if="hasComment(trip)"
                 class="bx bx-message comment-icon ml-2"
                 v-b-tooltip.hover
                 title="Kliknij, aby zobaczyć komentarz"
                 @click="showCommentModal(trip)"
              ></i>
            </td>
            <td>{{ trip.date }}</td>
            <td>
              <application-trip-kilometers
                :trip="trip"
                :application-id="applicationId"
                :can-edit-application="canChangeAmountOrAcceptance"
              />
            </td>
            <td>
              {{ trip.compensationAmount ? trip.compensationAmount.toFixed(2) + ' zł' : '-' }}
              <i v-if="trip.kilometerRate"
                 class="bx bx-info-circle ml-1"
                 v-b-tooltip.hover
                 :title="`Stawka za kilometr: ${trip.kilometerRate.toFixed(2)} zł`"
                 style="color: #495057; font-size: 1rem; vertical-align: middle;"
              ></i>
            </td>
            <td>
              <span 
                data-toggle="tooltip" 
                data-placement="top"
                :title="'Dodane: ' + trip.createdAt"
              >
                <span 
                  v-if="isTripFromGuardian(trip)"
                  class="badge badge-soft-info mr-1"
                  v-b-tooltip.hover
                  title="Opiekun"
                >
                  O
                </span>
                <span 
                  v-if="isTripFromFoundationEmployee(trip)"
                  class="badge badge-soft-primary mr-1"
                  v-b-tooltip.hover
                  title="Pracownik fundacji"
                >
                  P
                </span>
                {{ trip.createdBy }}
              </span>
            </td>
            <td>
              <application-item-acceptance
                :item="trip"
                :application-id="applicationId"
                :can-edit-application="canChangeAmountOrAcceptance"
                type="trip"
              />
            </td>
            <td>
              <div class="d-flex">
                <div v-b-tooltip.hover :title="isTripFromFoundationEmployee(trip) ? 'Usuń przejazd' : 'Można usuwać tylko przejazdy dodane przez pracownika fundacji'">
                  <button 
                    type="button" 
                    :disabled="!canEditApplication || isTripFromGuardian(trip)"
                    @click="handleTripRemove(trip.tripId)"
                    class="btn btn-sm btn-danger waves-effect waves-light"
                  >
                    <i class="bx bx-trash bx-fw"></i>
                  </button>
                </div>
                <file-preview-modal
                  :ref="`preview-modal-${trip.tripId}`"
                  :load-preview-callback="getPreviewCallback(trip.tripId)"
                  :download-callback="getDownloadCallback(trip.tripId)"
                  :title="`Podgląd: ${trip.startLocation} - ${trip.endLocation}`"
                  :file-info="trip.file"
                  :modal-size="{ width: '41vw', height: '92vh' }"
                  position="right"
                  hide-element-id="sidebar"
                  expand-element-id="main-content"
                />
              </div>
            </td>
          </tr>
          <tr>
            <th></th>
            <td style="text-align: right;" colspan="3">Suma kilometrów:</td>
            <td style="font-weight: bold;">{{ totalKilometers }} km</td>
            <td style="font-weight: bold;">{{ totalCompensation.toFixed(2) }} zł</td>
            <td colspan="2"></td>
          </tr>
        </tbody>
      </table>
    </div>
  
    <!-- Modal komentarza -->
    <b-modal
      v-model="commentModalVisible"
      title="Komentarz do przejazdu"
      hide-footer
      centered
      size="md"
      header-class="border-bottom-0 pb-0"
      content-class="p-0"
    >
      <div v-if="selectedTripComment" class="comment-modal-content p-3">
        <div class="comment-trip-info mb-3">
          <p class="mb-1">
            <strong>Trasa:</strong> {{ selectedTripComment.startLocation }} - {{ selectedTripComment.endLocation }}
          </p>
          <p class="mb-1">
            <strong>Data:</strong> {{ selectedTripComment.date }}
          </p>
          <p class="mb-1">
            <strong>Kilometry:</strong> {{ selectedTripComment.kilometers }} km
          </p>
          <p class="mb-1">
            <strong>Dodał(a):</strong> {{ selectedTripComment.createdBy }}
          </p>
        </div>
        <div class="comment-content p-3 bg-light rounded">
          <p class="mb-0">{{ selectedTripComment.comment }}</p>
        </div>
        <div class="text-right mt-3">
          <button @click="hideCommentModal" class="btn btn-sm btn-secondary">Zamknij</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<style scoped>
.preview-icon {
  cursor: pointer;
  font-size: 1rem;
  color: #495057;
  vertical-align: middle;
}

.preview-icon:hover {
  color: #000;
}

.comment-icon {
  cursor: pointer;
  font-size: 1rem;
  color: #495057;
  vertical-align: middle;
}

.comment-icon:hover {
  color: #000;
}

.comment-modal-content {
  max-height: 80vh;
  overflow-y: auto;
}

.comment-content {
  border-left: 3px solid #556ee6;
  word-break: break-word;
}

.d-flex > div {
  display: inline-block;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}

.btn-sm i {
  font-size: 1.1rem;
}
</style> 
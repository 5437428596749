<script>
import notificationMixin from '@/mixins/notificationMixin';
import Repository from "@/app/repository/repository-factory";
import EventBus from "@/event-bus";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  name: "ApplicationInvoiceNumber",
  mixins: [notificationMixin],
  props: {
    invoice: {
      type: Object,
      required: true
    },
    applicationId: {
      type: String,
      required: true
    },
    canEditApplication: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      newNumberInput: "",
      loading: false,
      isNumberValid: null
    };
  },
  computed: {
    canEdit() {
      return this.canEditApplication;
    },
    validateForm() {
      return this.isNumberValid && 
             this.newNumberInput !== this.invoice.number;
    },
    modalId() {
      return "edit-invoice-number-modal-" + this.invoice.invoiceId;
    },
    getNumberErrorMessage() {
      if (this.newNumberInput) {
        if (this.newNumberInput === this.invoice.number) {
          return "Nowy numer nie może być taki sam jak aktualny";
        }
        if (this.newNumberInput.length < 5) {
          return "Numer faktury musi mieć co najmniej 5 znaków";
        }
        return "";
      }
      return "";
    }
  },
  methods: {
    validateNumber(value) {
      if (!value) {
        this.isNumberValid = null;
        return;
      }
      
      const isValidLength = value.length >= 5;
      const isSameNumber = value === this.invoice.number;

      if (isValidLength && isSameNumber) {
        this.isNumberValid = false;
      } else {
        this.isNumberValid = isValidLength;
      }
    },
    openModal() {
      this.newNumberInput = this.invoice.number;
      this.$refs.editInvoiceModal.show();
    },
    resetForm() {
      this.newNumberInput = "";
      this.isNumberValid = null;
    },
    cancelEdit() {
      this.$refs.editInvoiceModal.hide();
    },
    confirmEdit() {
      if (!this.validateForm) {
        this.notifyError("Nowy numer faktury musi być inny niż obecny i mieć co najmniej 5 znaków.");
        return;
      }
      this.loading = true;
      ApplicationApplicationRepository.changeInvoiceNumber(
        this.applicationId,
        this.invoice.invoiceId,
        {
          number: this.newNumberInput
        }
      )
        .then(() => {
          EventBus.$emit("applicationChanged", "");
          this.$refs.editInvoiceModal.hide();
          this.notifySuccess("Numer faktury został zaktualizowany!");
        })
        .catch((error) => {
          this.notifyError(error.response?.data?.message || "Błąd przy aktualizacji numeru faktury.");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<template>
  <div class="d-flex align-items-center">
    <span class="text-break" style="font-weight: 600;">
      {{ invoice.number }}
    </span>
    <b-button
      v-if="canEdit"
      variant="link"
      size="sm"
      @click="openModal"
      v-b-tooltip.hover
      title="Edytuj numer faktury"
      class="p-0 ml-2"
      style="line-height: 1;"
    >
      <i class="bx bx-edit" style="color: black;"></i>
    </b-button>

    <!-- Modal do edycji numeru faktury -->
    <b-modal
      :id="modalId"
      ref="editInvoiceModal"
      title="Zmiana numeru faktury"
      @hidden="resetForm"
      hide-footer
    >
      <div class="mb-4">
        <p class="mb-2"><strong>Aktualny numer:</strong> {{ invoice.number }}</p>
        <p class="mb-2"><strong>Data:</strong> {{ invoice.date }}</p>
        <p class="mb-2">
          <strong>Kwota:</strong> 
          {{ (invoice.amount || 0).toFixed(2) }} zł
          <i v-if="invoice.correctAmount" 
             class="bx bx-info-circle ml-1 text-danger"
             v-b-tooltip.hover.right
             :title="`Kwota skorygowana z ${invoice.correctAmount.amountBeforeCorrection.toFixed(2)} na ${invoice.amount.toFixed(2)} zł
(${invoice.correctAmount.correctedBy}, ${invoice.correctAmount.correctedAt})`"
          ></i>
        </p>
        <p class="mb-2"><strong>Utworzone przez:</strong> {{ invoice.createdBy }}</p>
      </div>

      <b-form @submit.prevent="confirmEdit">
        <b-form-group label="Nowy numer faktury">
          <b-form-input
            v-model="newNumberInput"
            type="text"
            required
            placeholder="Nowy numer faktury"
            :state="isNumberValid"
            @input="validateNumber"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!isNumberValid && newNumberInput">
            {{ getNumberErrorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="text-right mt-4">
          <b-button 
            variant="primary" 
            class="px-4"
            type="submit" 
            :disabled="!validateForm || loading"
          >
            Zmień numer
          </b-button>
          <b-button 
            variant="link" 
            @click="cancelEdit" 
            :disabled="loading"
            class="ml-2"
          >
            Zamknij
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<style scoped>
/* Opcjonalne styles specyficzne dla komponentu */
.bx-info-circle {
  cursor: help;
}
</style> 
<script>
import ApplicationAdditionalDocumentAdd from "./application-additional-document-add.vue";
import FilePreviewModal from "@/components/common/file-preview-modal";
import Repository from "@/app/repository/repository-factory";
import { UserType } from "@/constants/invoice-constants";
import notificationMixin from '@/mixins/notificationMixin';
import EventBus from "@/event-bus";

const AdditionalDocumentsRepository = Repository.get("AdditionalDocumentsRepository");

export default {
  name: "ApplicationAdditionalDocuments",
  mixins: [notificationMixin],
  components: {
    ApplicationAdditionalDocumentAdd,
    FilePreviewModal
  },
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    },
    canEditApplication: {
      type: Boolean,
      required: true
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      documents: [],
      isLoading: false
    };
  },
  computed: {
    documentsCount() {
      return this.documents.length;
    }
  },
  methods: {
    async loadDocuments() {
      try {
        this.isLoading = true;
        if (!this.applicationId || this.applicationId === '') {
          this.documents = [];
          return;
        }
        const response = await AdditionalDocumentsRepository.getDocuments(this.applicationId);
        this.documents = response.data;
      } catch (error) {
        console.error('Błąd podczas ładowania dokumentów:', error.response || error);
        this.documents = [];
      } finally {
        this.isLoading = false;
      }
    },

    isDocumentFromFoundationEmployee(doc) {
      return doc.userType === UserType.FOUNDATION_EMPLOYEE;
    },

    isDocumentFromGuardian(doc) {
      return doc.userType === UserType.GUARDIAN;
    },

    async handleDocumentRemove(documentId) {
      try {
        const isConfirmed = await this.$swal({
          title: "Czy na pewno chcesz usunąć dokument?",
          text: "Tej operacji nie można cofnąć!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Tak, usuń",
          cancelButtonText: "Anuluj"
        });
        if (!isConfirmed.value) {
          return;
        }
        await AdditionalDocumentsRepository.removeDocument(this.applicationId, documentId);
        EventBus.$emit('applicationChanged', '');
        this.loadDocuments();
        this.notifySuccess('Dokument został usunięty!');
      } catch (error) {
        this.notifyError(error.response?.data?.message || 'Nie udało się usunąć dokumentu');
      }
    },

    showFilePreview(doc) {
      this.$refs[`preview-modal-${doc.documentId}`][0].showModal();
    },

    getPreviewCallback(documentId) {
      return () => AdditionalDocumentsRepository.previewDocument(this.applicationId, documentId);
    },

    getDownloadCallback(documentId) {
      return () => AdditionalDocumentsRepository.downloadDocument(this.applicationId, documentId);
    }
  },
  mounted() {
    this.loadDocuments();
    EventBus.$on('refreshDocuments', () => {
      this.loadDocuments();
    });
  },
  beforeDestroy() {
    EventBus.$off('refreshDocuments');
  },
  watch: {
    isActive: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.loadDocuments();
        }
      }
    },
    applicationId: {
      immediate: true,
      handler(newValue) {
        if (newValue && this.isActive) {
          this.loadDocuments();
        }
      }
    }
  }
};
</script>

<template>
  <div>
    <p class="card-title-desc">
      Dodaj dokumenty dodatkowe do wniosku.
    </p>

    <application-additional-document-add
      v-if="canEditApplication"
      :application-id="applicationId"
      :application-expected-version="applicationExpectedVersion">
    </application-additional-document-add>

    <div v-if="isLoading" class="text-center my-3">
      <b-spinner variant="primary"></b-spinner>
    </div>

    <div v-else class="table-responsive">
      <table class="table mb-0">
        <thead class="thead-light">
          <tr>
            <th>Lp.</th>
            <th>Nazwa dokumentu</th>
            <th>Data dodania</th>
            <th>Dodał(a)</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(doc, index) in documents" :key="doc.documentId">
            <td>{{ index + 1 }}</td>
            <td style="font-weight: 600;">
              {{ doc.name }}
              <i v-if="doc.file"
                 class="bx bx-file preview-icon ml-2"
                 v-b-tooltip.hover
                 title="Podgląd"
                 @click="showFilePreview(doc)"
              ></i>
            </td>
            <td>{{ doc.createdAt }}</td>
            <td>
              <span 
                data-toggle="tooltip" 
                data-placement="top"
                :title="'Dodane: ' + doc.createdAt"
              >
                <span 
                  v-if="isDocumentFromGuardian(doc)"
                  class="badge badge-soft-info mr-1"
                  v-b-tooltip.hover
                  title="Opiekun"
                >
                  O
                </span>
                <span 
                  v-if="isDocumentFromFoundationEmployee(doc)"
                  class="badge badge-soft-primary mr-1"
                  v-b-tooltip.hover
                  title="Pracownik fundacji"
                >
                  P
                </span>
                {{ doc.createdBy }}
              </span>
            </td>
            <td>
              <div class="d-flex">
                <div v-b-tooltip.hover :title="isDocumentFromFoundationEmployee(doc) ? 'Usuń dokument' : 'Można usuwać tylko dokumenty dodane przez pracownika fundacji'">
                  <button 
                    type="button" 
                    :disabled="!canEditApplication || !isDocumentFromFoundationEmployee(doc)"
                    @click="handleDocumentRemove(doc.documentId)"
                    class="btn btn-sm btn-danger waves-effect waves-light"
                  >
                    <i class="bx bx-trash bx-fw"></i>
                  </button>
                </div>
                <file-preview-modal
                  :ref="`preview-modal-${doc.documentId}`"
                  :load-preview-callback="getPreviewCallback(doc.documentId)"
                  :download-callback="getDownloadCallback(doc.documentId)"
                  :title="`Podgląd: ${doc.name}`"
                  :file-info="doc.file"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>
.preview-icon {
  cursor: pointer;
  font-size: 1rem;
  color: #495057;
  vertical-align: middle;
}

.preview-icon:hover {
  color: #000;
}

.d-flex > div {
  display: inline-block;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
}

.btn-sm i {
  font-size: 1.1rem;
}
</style> 
<script>
import notificationMixin from '@/mixins/notificationMixin';
import Repository from "@/app/repository/repository-factory";
import EventBus from "@/event-bus";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  name: "ApplicationTripKilometers",
  mixins: [notificationMixin],
  props: {
    trip: {
      type: Object,
      required: true
    },
    applicationId: {
      type: String,
      required: true
    },
    canEditApplication: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      newKilometersInput: "",
      reason: "",
      loading: false,
      isKilometersValid: null
    };
  },
  computed: {
    formattedKilometers() {
      return (this.trip.kilometers || 0) + " km";
    },
    canEdit() {
      return this.canEditApplication;
    },
    validateForm() {
      return this.isKilometersValid && 
             parseFloat(this.newKilometersInput) !== this.trip.kilometers && 
             this.reason.length >= 8;
    },
    modalId() {
      return "edit-trip-modal-" + this.trip.tripId;
    },
    newKilometers() {
      return this.isKilometersValid ? parseFloat(this.newKilometersInput) : null;
    },
    getKilometersErrorMessage() {
      if (this.newKilometersInput) {
        const value = parseFloat(this.newKilometersInput);
        if (value === this.trip.kilometers) {
          return "Nowa liczba kilometrów nie może być taka sama jak aktualna";
        }
        return "Wprowadź poprawną liczbę kilometrów (np. 123.5)";
      }
      return "";
    },
    formattedCompensationAmount() {
      return (this.trip.compensationAmount || 0).toFixed(2) + " zł";
    }
  },
  methods: {
    validateKilometers(value) {
      if (!value) {
        this.isKilometersValid = null;
        return;
      }
      
      // Sprawdzanie czy format jest poprawny (liczba z maksymalnie 2 miejscami po przecinku)
      const kilometersRegex = /^\d+(\.\d{0,2})?$/;
      const isValidFormat = kilometersRegex.test(value) && parseFloat(value) > 0;
      const isSameKilometers = parseFloat(value) === this.trip.kilometers;

      if (isValidFormat && isSameKilometers) {
        this.isKilometersValid = false;
      } else {
        this.isKilometersValid = isValidFormat;
      }
    },
    openModal() {
      this.$refs.editTripModal.show();
    },
    resetForm() {
      this.newKilometersInput = "";
      this.reason = "";
      this.isKilometersValid = null;
    },
    cancelEdit() {
      this.$refs.editTripModal.hide();
    },
    confirmEdit() {
      if (!this.validateForm) {
        this.notifyError("Powód korekty musi zawierać minimum 8 znaków, a liczba kilometrów musi być zmieniona i poprawna.");
        return;
      }
      this.loading = true;
      ApplicationApplicationRepository.correctTripKilometers(
        this.applicationId,
        this.trip.tripId,
        {
          newKilometers: this.newKilometers,
          reason: this.reason
        }
      )
        .then(() => {
          EventBus.$emit("applicationChanged", "");
          this.$refs.editTripModal.hide();
          this.notifySuccess("Liczba kilometrów została zaktualizowana!");
        })
        .catch((error) => {
          this.notifyError(error.response?.data?.message || "Błąd przy aktualizacji liczby kilometrów.");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center">
      <i v-if="trip.correctKm" 
         class="bx bx-info-circle mr-1 text-danger"
         v-b-tooltip.hover.right
         :title="`Liczba kilometrów skorygowana z ${trip.correctKm.kmBeforeCorrection} na ${trip.kilometers} km
(${trip.correctKm.correctedBy}, ${trip.correctKm.correctedAt})`"
      ></i>
      <span>{{ formattedKilometers }}</span>
    </div>
    <b-button
      v-if="canEdit"
      variant="link"
      size="sm"
      @click="openModal"
      v-b-tooltip.hover
      title="Edytuj kilometry"
      class="p-0 ml-2"
      style="line-height: 1;"
    >
      <i class="bx bx-edit" style="color: black;"></i>
    </b-button>

    <!-- Modal do edycji kilometrów oraz podania powodu korekty -->
    <b-modal
      :id="modalId"
      ref="editTripModal"
      title="Korekta kilometrów przejazdu"
      @hidden="resetForm"
      hide-footer
    >
      <div class="mb-4">
        <p class="mb-2"><strong>Aktualna liczba kilometrów:</strong> {{ formattedKilometers }}</p>
        <p class="mb-2"><strong>Aktualna kwota zwrotu:</strong> {{ formattedCompensationAmount }}</p>
        <p class="mb-2"><strong>Trasa:</strong> {{ trip.startLocation }} - {{ trip.endLocation }}</p>
        <p class="mb-2"><strong>Data:</strong> {{ trip.date }}</p>
        <p class="mb-2"><strong>Utworzone przez:</strong> {{ trip.createdBy }}</p>
      </div>

      <b-form @submit.prevent="confirmEdit">
        <b-form-group label="Nowa liczba kilometrów">
          <b-form-input
            v-model="newKilometersInput"
            type="text"
            required
            placeholder="Nowa liczba kilometrów"
            :state="isKilometersValid"
            @input="validateKilometers"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!isKilometersValid && newKilometersInput">
            {{ getKilometersErrorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Powód korekty">
          <b-form-textarea
            v-model.trim="reason"
            placeholder="Powód korekty"
            rows="3"
            required
          ></b-form-textarea>
        </b-form-group>
        <div class="text-right mt-4">
          <b-button 
            variant="primary" 
            class="px-4"
            type="submit" 
            :disabled="!validateForm || loading"
          >
            Koryguj kilometry
          </b-button>
          <b-button 
            variant="link" 
            @click="cancelEdit" 
            :disabled="loading"
            class="ml-2"
          >
            Zamknij
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<style scoped>
.bx-info-circle {
  color: #dc3545;
  font-size: 1rem;
}
</style>
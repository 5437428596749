<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import ApplicationRoadCardComplete from "@/components/application/application-roadcard-complete";
import ApplicationStatuses from "@/components/application/application-statuses";
import ApplicationChangeComment from "@/components/application/application-change-comment";
import ApplicationInvoices from "@/components/application/application-invoices";
import ApplicationWithdrawals from "@/components/application/application-withdrawals";
import ApplicationAdditionalDocuments from "@/components/application/application-additional-documents";
import ApplicationTrips from "@/components/application/application-trips";
import Preloader from "@/components/widgets/preloader";
import Repository from "../../../app/repository/repository-factory";
import EventBus from "@/event-bus";
import notificationMixin from '@/mixins/notificationMixin';
import ApplicationDetails from "@/components/application/application-details";
import { UserType } from "@/constants/invoice-constants";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");
const ApplicationSettlementRepository = Repository.get("ApplicationSettlementRepository");
const ApplicationBeneficiaryRepository = Repository.get("ApplicationBeneficiaryRepository");

export default {
  name: "ApplicationEdit",
  mixins: [notificationMixin],
  components: {
    Layout,
    PageHeader,
    Preloader,
    ApplicationRoadCardComplete,
    ApplicationStatuses,
    ApplicationChangeComment,
    ApplicationInvoices,
    ApplicationDetails,
    ApplicationWithdrawals,
    ApplicationAdditionalDocuments,
    ApplicationTrips
  },
  data() {
    return {
      activeTab: 0,
      title: 'Edycja wniosku',
      items: [],
      isNumberEdition: false,
      preloader: true,
      scrollPosition: 0,
      application: {
        number: '',
        applicationId: '',
        version: 0,
        invoices: [],
        additionalDocuments: [],
        trips: [],
        statuses: [{
          statusName: 'in_preparation'
        }],
        roadCard: {
          amount: 0
        },
        beneficiaryId: null,
        comment: '',
        amountToBePaid: 0,
        invoiceSumAmount: 0
      },
      settlement: {
        settlementId: null,
        version: 0,
        withdrawals: [],
        status: '',
        amountToSettled: 0
      },
      beneficiary: {
        beneficiaryId: '',
        firstName: '',
        lastName: '',
        personalAccountBalance: 0,
        publicAccountBalance: 0,
        onePercentAccountBalance: 0,
        foundationAccountBalance: 0
      },
      statuses: [
        { value: '', text: 'Wybierz status' },
        { value: 'in_preparation', text: 'W trakcie przygotowania' },
        { value: 'submitted_by_guardian', text: 'Złożony przez opiekuna' },
        { value: 'for_approval', text: 'Przekazany do zatwierdzenia' },
        { value: 'approved', text: 'Zatwierdzony' },
        { value: 'correction', text: 'Korekta' },
        { value: 'incorrect', text: 'Niepoprawny' },
        { value: 'in_settlement', text: 'W rozliczeniu' },
        { value: 'awaiting_settlement', text: 'Oczekuje na rozliczenie' },
        { value: 'partially_settled', text: 'Częściowo rozliczony' },
        { value: 'settled', text: 'Rozliczony' },
      ],
    };
  },
  mounted() {
    if (!this.$route.params.id) {
      return;
    }

    EventBus.$on('applicationNumberChanged', () => {
      this.isNumberEdition = false;
      this.getApplication(this.$route.params.id, true);
    });

    EventBus.$on('applicationChanged', () => {
        this.getApplication(this.$route.params.id, true);
    });

    EventBus.$on('applicationSettlementChanged', async () => {
      const lastStatus = this.application?.statuses?.[this.application.statuses.length - 1]?.statusName;
      if (this.isSettlementStatus(lastStatus)) {
        await this._fetchSettlement(this.$route.params.id);
        await this.getBeneficiary(this.application.beneficiaryId);
      }
    });

    // Dodajemy obsługę naciśnięcia przycisku wstecz przeglądarki
    window.addEventListener('popstate', this.handleBackButton);
  },
  created() {
    if (!this.$route.params.id) {
      return;
    }

    // Initialize breadcrumb items with preserved query params
    this.items = [
      {
        text: 'Wnioski',
        to: {
          name: 'Raport wniosków',
          query: this.$route.query
        }
      },
      {
        text: 'Edycja',
        active: true,
      },
    ];

    this.getApplication(this.$route.params.id);
  },
  watch: {
    preloader(newValue) {
      if (newValue) {
        this.scrollPosition = window.scrollY || document.documentElement.scrollTop;
      }
    },
    '$route.params.id': {
      immediate: true,
      handler(newId) {
        if (newId) {
          this.scrollPosition = 0; // Reset przy zmianie ID wniosku
          this.preloader = true;
          this.getApplication(newId);
        }
      }
    },
    filter: function () {
      this.currentPage = 1;
    }
  },
  methods: {
    handleTabActivation(newTabId) {
      if (newTabId === 'trips-tab') {
        EventBus.$emit('documentAdded');
      }
      if (newTabId === 'additional-documents-tab') {
        EventBus.$emit('refreshDocuments');
      }
    },
    enableNumberEdition() {
      this.isNumberEdition = true;
    },
    async removeApplication() {
      try {
        const isConfirmed = await this.$swal({
          title: 'Czy na pewno chcesz usunąć wniosek?',
          text: "Tej operacji nie można cofnąć!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Tak, usuń',
          cancelButtonText: 'Anuluj'
        });

        if (isConfirmed.value) {
          await ApplicationApplicationRepository.remove(this.application.applicationId);
          await this.notifySuccess('Wniosek został usunięty!', { timer: 1500 });
          // Keep the query parameters when navigating back after removal
          this.$router.push({
            name: "Raport wniosków",
            query: this.$route.query
          });
        }
      } catch (error) {
        this.notifyError(error.response?.data?.message || 'Nie udało się usunąć wniosku');
      }
    },
    isSettlementStatus(status) {
      return ['in_settlement', 'awaiting_settlement', 'partially_settled', 'settled'].includes(status);
    },
    getEmptySettlement() {
      return {
        settlementId: null,
        version: 0,
        withdrawals: [],
        status: '',
        amountToSettled: 0
      };
    },
    async getApplication(applicationId, usePreloader = true) {
      try {
        if (usePreloader) {
          this.preloader = true;
        }
        
        const { data } = await ApplicationApplicationRepository.getItem(applicationId);
        this.application = data;
        await this.getBeneficiary(this.application.beneficiaryId);

        // Pobieramy settlement tylko dla odpowiednich statusów
        const lastStatus = data.statuses[data.statuses.length - 1]?.statusName;
        if (this.isSettlementStatus(lastStatus)) {
          await this._fetchSettlement(applicationId);
        } else {
          // Resetujemy settlement dla innych statusów
          this.settlement = this.getEmptySettlement();
        }
      } catch (error) {
        console.error('Błąd podczas pobierania danych aplikacji:', error);
        this.notifyError('Wystąpił błąd podczas pobierania danych wniosku. Spróbuj odświeżyć stronę.');
      } finally {
        if (usePreloader) {
          this.preloader = false;
        }
      }
    },
    async _fetchSettlement(applicationId) {
      const promise = ApplicationSettlementRepository.getItem(applicationId);

      return promise.then((data) => {
        this.settlement = data.data;
      }).catch(() => {
        this.preloader = false;
        this.settlement = this.getEmptySettlement();
      })
    },
    getBeneficiary(beneficiaryId) {
      const promise = ApplicationBeneficiaryRepository.getItem(beneficiaryId);

      return promise.then((data) => {
        this.beneficiary = data.data;

      }).catch(error => {
        this.preloader = false;
        console.log(error);
      })
    },
    getPdf: function () {
      const promise = ApplicationApplicationRepository.getPDF(this.$route.params.id);
      return promise.then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.application.number + '_wniosek.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch(error => {
        console.log(error);
      })
    },
    lastStatus() {
      if (this.settlement?.status) {
        return this.settlement.status;
      }

      const lastStatus = this.application?.statuses?.[this.application.statuses.length - 1];
      return lastStatus?.statusName || 'in_preparation';
    },
    canEditApplication() {
      if (this.lastStatus() === 'in_preparation' || this.lastStatus() === 'correction' || this.lastStatus() === 'submitted_by_guardian') {
        return true;
      }

      return false;
    },
    canChangeAmountOrAcceptance() {
      const status = this.lastStatus();
      const allowedStatusesForGuardianApplication = ['submitted_by_guardian', 'correction'];
      const allowedStatusesForEmployeeApplication = ['in_preparation', 'submitted_by_guardian', 'correction'];

      if (this.isApplicationFromGuardian()) {
        return allowedStatusesForGuardianApplication.includes(status);
      }

      if (this.isApplicationFromFoundationEmployee()) {
        return allowedStatusesForEmployeeApplication.includes(status);
      }

      return false;
    },
    canWithdrawal() {
      if (this.lastStatus() === 'awaiting_settlement' || this.lastStatus() === 'partially_settled') {
        return true;
      }
      return false;
    },
    isApplicationFromGuardian() {
      return this.application.userType === UserType.GUARDIAN;
    },
    isApplicationFromFoundationEmployee() {
      return this.application.userType === UserType.FOUNDATION_EMPLOYEE;
    },
    // Metoda obsługująca przycisk wstecz przeglądarki
    handleBackButton(event) {
      // Sprawdzamy, czy zdarzenie zawiera dane stanu i mamy parametry zapytania do zachowania
      if (event.state && this.$route.query && Object.keys(this.$route.query).length > 0) {
        const currentQuery = { ...this.$route.query };

        // Używamy setTimeout(0), aby wykonać kod na końcu kolejki zdarzeń
        // Po tym jak przeglądarka zakończy nawigację wstecz
        setTimeout(() => {
          // Sprawdzamy, czy jesteśmy na stronie z listą wniosków
          if (this.$router.currentRoute.name === 'Raport wniosków') {
            this.$router.replace({
              name: 'Raport wniosków',
              query: currentQuery
            }).catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
            });
          }
        }, 0);
      }
    },
    beforeDestroy() {
      EventBus.$off('applicationNumberChanged');
      EventBus.$off('applicationChanged');
      EventBus.$off('applicationSettlementChanged');

      // Usuwamy event listener przy zniszczeniu komponentu
      window.removeEventListener('popstate', this.handleBackButton);
    }
  }
}
</script>

<template>

  <Layout>
    <preloader v-model="preloader" :scroll-position="scrollPosition"></preloader>
    <template v-if="!preloader">
      <PageHeader :title="title" :items="items" />

      <b-alert variant="success" v-if="application && lastStatus() === 'settled'" show>
        Procesowanie wniosku zostało zakończone!
      </b-alert>

      <div class="row">
        <div id="sidebar" class="col-lg-3">

          <!-- Dane wniosku -->
          <application-details :application="application" :beneficiary="beneficiary" :isNumberEdition="isNumberEdition"
            :isEditable="canEditApplication()" @edit-number="enableNumberEdition()"
            @remove-application="removeApplication()" />

          <!-- Pobierz wniosek -->
          <div class="card" v-if="!canEditApplication()">
            <div class="card-body">
              <h4 class="card-title">Pobierz wniosek</h4>
              <p class="card-title-desc">
                Pobierz wniosek do akceptacji.
              </p>
              <button type="button" v-on:click="getPdf" class="btn btn-lg btn-success waves-effect waves-light">
                <i class="bx bxs-file-pdf mr-1"></i> Pobierz wniosek PDF
              </button>
            </div>
          </div>

          <!-- Karta drogowa -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Karta drogowa</h4>
              <p class="card-title-desc">
                Wypełnij dane dotyczące karty drogowej.
              </p>
              <application-road-card-complete v-if="application && application.applicationId"
                :application-id="application.applicationId" :application-expected-version="application.version"
                :road-card="application.roadCard" :disabled="!canEditApplication()"
                :has-trips="application.trips.length > 0">
              </application-road-card-complete>
            </div>
          </div>

          <!-- Statusy -->
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Statusy wniosku</h4>
              <p class="card-title-desc">
              </p>

              <application-statuses v-if="application && application.applicationId"
                :application-id="application.applicationId" :application-expected-version="application.version"
                :application-statuses="application.statuses" :statuses="statuses"
                :settlement-status="settlement?.status"
                :settlement-date="settlement?.withdrawals && settlement.withdrawals.length > 0 ? settlement.withdrawals[settlement.withdrawals.length - 1].createdAt : settlement?.createdAt"
                :is-created-by-guardian="isApplicationFromGuardian()"
                :disabled="preloader"></application-statuses>
            </div>
          </div>
        </div>

        <div id="main-content" class="col-lg-9">

          <div class="card" v-if="settlement && beneficiary">
            <div class="card-body">
              <h4 class="card-title">Aktualne saldo</h4>

              <div class="row">
                <div class="col-lg-3">
                  Konto imienne: <strong>{{ beneficiary.personalAccountBalance }} zł</strong>
                </div>
                <div class="col-lg-3">
                  Zbiórka publiczna: <strong>{{ beneficiary.publicAccountBalance }} zł</strong>
                </div>
                <div class="col-lg-3">
                  Konto 1,5%: <strong>{{ beneficiary.onePercentAccountBalance }} zł</strong>
                </div>
                <div class="col-lg-3">
                  Pomoc fundacji: <strong>{{ beneficiary.foundationAccountBalance }} zł</strong>
                </div>
              </div>
            </div>
          </div>

          <application-withdrawals v-if="settlement" :settlement="settlement" :can-withdrawal="canWithdrawal()"
            :application-id="application.applicationId" />

          <div class="card">
            <div class="card-body">
              <div class="tabs-wrapper" style="position: relative;">
                <b-tabs content-class="mt-3" @tab-click="handleTabActivation" ref="tabs" v-model="activeTab">
                  <b-tab :title="'Faktury (' + (application?.invoices?.length || 0) + ')'" id="invoices-tab">
                    <application-invoices v-if="application" :application-id="application.applicationId"
                      :application-expected-version="application.version"
                      :beneficiary-id="application.beneficiaryId || ''" :invoices="application.invoices"
                      :can-edit-application="canEditApplication()"
                      :can-change-amount-or-acceptance="canChangeAmountOrAcceptance()"
                      :invoice-sum-total="application.invoiceSumAmount || 0" />
                  </b-tab>

                  <b-tab :title="'Przejazdy (' + (application?.trips?.length || 0) + ')'" id="trips-tab">
                    <application-trips v-if="application" :application-id="application.applicationId"
                      :application-expected-version="application.version" :trips="application.trips"
                      :can-edit-application="canEditApplication()"
                      :can-change-amount-or-acceptance="canChangeAmountOrAcceptance()" />
                  </b-tab>

                  <b-tab :title="'Dokumenty dodatkowe (' + (application?.additionalDocuments?.length || 0) + ')'"
                    id="additional-documents-tab">
                    <application-additional-documents v-if="application" :application-id="application.applicationId"
                      :application-expected-version="application.version" :can-edit-application="canEditApplication()"
                      :is-active="activeTab === 2" />
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </div>

          <b-alert variant="info" v-if="application" show class="mb-3" style="font-size: 1.5em; text-align: center;">
            <i class="bx bx-wallet mr-2"></i>Kwota wniosku: {{ Number(application.amountToBePaid).toFixed(2) }} zł
            <i class="bx bx-info-circle ml-1 mb-1"
              v-b-tooltip.hover.html="'Kwota do wypłaty obejmuje zatwierdzone faktury oraz przejazdy z karty drogowej.'"
              style="vertical-align: middle; cursor: pointer; font-size: 0.8em;"></i>
          </b-alert>

          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Komentarz</h4>
              <p class="card-title-desc">
                Dodaj uwagi do wniosku.
              </p>

              <application-change-comment v-if="application && application.applicationId"
                :application-id="application.applicationId" :application-comment="application.comment" />

            </div>
          </div>


        </div>
      </div>
      <!-- end row -->
    </template>
  </Layout>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import { BButton, BSpinner } from 'bootstrap-vue';
import EventBus from '../../event-bus'
import notificationMixin from '@/mixins/notificationMixin';
import Repository from "../../app/repository/repository-factory";
import {decimal, maxLength, required} from "vuelidate/lib/validators";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  components: {DatePicker, BButton, BSpinner},
  mixins: [notificationMixin],
  props: {
    applicationId: {
      type: String,
      required: true,
      validator: value => value && value.length > 0
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    },
    roadCard: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasTrips: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isBusy: false,
      submitted: false,
      form: {
        amount: this.roadCard.amount,
        dateOfReceipt: this.roadCard.dateOfReceipt,
        dateOfLastTrip: this.roadCard.dateOfLastTrip
      },
    };
  },
  watch: {
    roadCard: {
      handler(newRoadCard) {
        this.form = {
          amount: newRoadCard.amount,
          dateOfReceipt: newRoadCard.dateOfReceipt,
          dateOfLastTrip: newRoadCard.dateOfLastTrip
        };
      },
      deep: true
    }
  },
  validations: {
    form: {
      amount: {required, decimal, maxLength: maxLength(12)},
      dateOfReceipt: {required},
      dateOfLastTrip: {required},
    }
  },
  computed: {
    isEditable() {
      return !this.disabled && !this.hasTrips;
    },
    roadCardHelpText() {
      if (this.hasTrips) {
        return "Wartość karty drogowej jest automatycznie wyliczana na podstawie dodanych przejazdów";
      }
      return "";
    }
  },
  methods: {
    async completeRoadCard() {
      this.formSubmit();
      if (this.$v.form.$error) return;

      this.isBusy = true;
      try {
        const payload = {
          ...this.form,
          version: this.applicationExpectedVersion
        };
        await ApplicationApplicationRepository.completeRoadCard(this.applicationId, payload);
        EventBus.$emit('applicationChanged', '');
        this.notifySuccess('Karta drogowa została rozliczona!');
      } catch (error) {
        this.notifyError(error.response.data.message);
      } finally {
        this.isBusy = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.form.$touch()
    },
    resetForm() {
      this.form = {
        amount: this.roadCard.amount,
        dateOfReceipt: this.roadCard.dateOfReceipt,
        dateOfLastTrip: this.roadCard.dateOfLastTrip
      };
      this.submitted = false;
      this.$v.form.$reset();
    }
  }
};
</script>
<template v-if="isBusy === false">
  <div>
    <div v-if="isEditable">
      <form class="needs-validation" @submit.prevent="formSubmit">
        <div v-if="hasTrips" class="alert alert-info mb-3">
          <i class="bx bx-info-circle mr-1"></i>
          {{ roadCardHelpText }}
        </div>
        <div class="row">
          <div class="col-md-12 col-lg-12">
            <div class="form-group">
              <label for="amount">Kwota:</label>
              <div class="input-group">
                <input
                  id="amount"
                  v-model="form.amount"
                  step="0.01"
                  min="0.00"
                  max="9999999999.99"
                  :disabled="!isEditable"
                  type="number"
                  class="form-control"
                  placeholder="Kwota"
                  :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
                />
                <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
                  <span v-if="!$v.form.amount.required">Ta wartość jest wymagana.</span>
                  <span v-if="!$v.form.amount.decimal">Wpisz wartość liczbową (np. 200.00).</span>
                  <span v-if="!$v.form.amount.greaterThanZero">Wartość musi być większa od 0.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="form-group">
              <label for="dateOfLastTrip">Data ostatniego przejazdu:</label>
              <date-picker
                id="dateOfLastTrip"
                v-model="form.dateOfLastTrip"
                :disabled="!isEditable"
                :first-day-of-week="1"
                placeholder="Data przejazdu"
                value-type="format"
                :class="{ 'is-invalid': submitted && $v.form.dateOfLastTrip.$error }"
                lang="pl">
              </date-picker>
              <div v-if="submitted && $v.form.dateOfLastTrip.$error" class="invalid-feedback">
                <span v-if="!$v.form.dateOfLastTrip.required">Ta wartość jest wymagana.<br></span>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="form-group">
              <label for="dateOfReceipt">Data wpływu:</label>
              <date-picker
                id="dateOfReceipt"
                v-model="form.dateOfReceipt"
                :disabled="!isEditable"
                placeholder="Data wpływu"
                :first-day-of-week="1"
                value-type="format"
                :class="{ 'is-invalid': submitted && $v.form.dateOfReceipt.$error }"
                lang="pl">
              </date-picker>
              <div v-if="submitted && $v.form.dateOfReceipt.$error" class="invalid-feedback">
                <span v-if="!$v.form.dateOfReceipt.required">Ta wartość jest wymagana.<br></span>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-lg-12">
            <div class="centered">
              <b-button variant="primary" @click="completeRoadCard" :disabled="!isEditable" class="waves-effect waves-light">
                <i class="bx bx-bus mr-1"></i> Aktualizuj kartę drogową
                <template v-if="isBusy">
                  <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
                </template>
              </b-button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-else>
      <div v-if="hasTrips" class="alert alert-info mb-3">
        <i class="bx bx-info-circle mr-1"></i>
        {{ roadCardHelpText }}
      </div>
      <div class="p-3">
        <div class="mb-4">
          <div class="text-muted mb-1">Kwota</div>
          <div class="h4 fw-bold mb-0">{{ roadCard.amount }} zł</div>
        </div>
        
        <div class="mb-4">
          <div class="text-muted mb-1">Data ostatniego przejazdu</div>
          <div class="h5 fw-normal mb-0">{{ roadCard.dateOfLastTrip }}</div>
        </div>
        
        <div>
          <div class="text-muted mb-1">Data wpływu</div>
          <div class="h5 fw-normal mb-0">{{ roadCard.dateOfReceipt }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import notificationMixin from '@/mixins/notificationMixin';
import Repository from "@/app/repository/repository-factory";
import EventBus from "@/event-bus";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  name: "ApplicationInvoiceAmount",
  mixins: [notificationMixin],
  props: {
    invoice: {
      type: Object,
      required: true
    },
    applicationId: {
      type: String,
      required: true
    },
    canEditApplication: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      newAmountInput: "",
      reason: "",
      loading: false,
      isAmountValid: null
    };
  },
  computed: {
    formattedAmount() {
      return (this.invoice.amount || 0).toFixed(2) + " zł";
    },
    canEdit() {
      return this.canEditApplication;
    },
    validateForm() {
      return this.isAmountValid && 
             this.newAmountInput !== this.invoice.amount.toString() && 
             this.reason.length >= 8;
    },
    modalId() {
      return "edit-invoice-modal-" + this.invoice.invoiceId;
    },
    newAmount() {
      return this.isAmountValid ? parseFloat(this.newAmountInput) : null;
    },
    getAmountErrorMessage() {
      if (this.newAmountInput) {
        const value = parseFloat(this.newAmountInput);
        if (value === this.invoice.amount) {
          return "Nowa kwota nie może być taka sama jak aktualna";
        }
        return "Wprowadź poprawną kwotę (np. 123.45)";
      }
      return "";
    }
  },
  methods: {
    validateAmount(value) {
      if (!value) {
        this.isAmountValid = null;
        return;
      }
      
      // Sprawdzanie czy format jest poprawny (liczba z maksymalnie 2 miejscami po przecinku)
      const amountRegex = /^\d+(\.\d{0,2})?$/;
      const isValidFormat = amountRegex.test(value) && parseFloat(value) > 0;
      const isSameAmount = parseFloat(value) === this.invoice.amount;

      if (isValidFormat && isSameAmount) {
        this.isAmountValid = false;
      } else {
        this.isAmountValid = isValidFormat;
      }
    },
    openModal() {
      this.$refs.editInvoiceModal.show();
    },
    resetForm() {
      this.newAmountInput = "";
      this.reason = "";
      this.isAmountValid = null;
    },
    cancelEdit() {
      this.$refs.editInvoiceModal.hide();
    },
    confirmEdit() {
      if (!this.validateForm) {
        this.notifyError("Powód korekty musi zawierać minimum 8 znaków, a kwota musi być zmieniona i poprawna.");
        return;
      }
      this.loading = true;
      ApplicationApplicationRepository.correctInvoiceAmount(
        this.applicationId,
        this.invoice.invoiceId,
        {
          amount: this.newAmount,
          reason: this.reason
        }
      )
        .then(() => {
          EventBus.$emit("applicationChanged", "");
          this.$refs.editInvoiceModal.hide();
          this.notifySuccess("Kwota faktury została zaktualizowana!");
        })
        .catch((error) => {
          this.notifyError(error.response?.data?.message || "Błąd przy aktualizacji kwoty faktury.");
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<template>
  <div class="d-flex align-items-center justify-content-end">
    <div class="d-flex align-items-center">
      <i v-if="invoice.correctAmount" 
         class="bx bx-info-circle mr-1 text-danger"
         v-b-tooltip.hover.right
         :title="`Kwota skorygowana z ${invoice.correctAmount.amountBeforeCorrection.toFixed(2)} na ${invoice.amount.toFixed(2)} zł
(${invoice.correctAmount.correctedBy}, ${invoice.correctAmount.correctedAt})`"
      ></i>
      <span>{{ formattedAmount }}</span>
    </div>
    <b-button
      v-if="canEdit"
      variant="link"
      size="sm"
      @click="openModal"
      v-b-tooltip.hover
      title="Edytuj kwotę"
      class="p-0 ml-2"
      style="line-height: 1;"
    >
      <i class="bx bx-edit" style="color: black;"></i>
    </b-button>

    <!-- Modal do edycji kwoty oraz podania powodu korekty -->
    <b-modal
      :id="modalId"
      ref="editInvoiceModal"
      title="Korekta kwoty faktury"
      @hidden="resetForm"
      hide-footer
    >
      <div class="mb-4">
        <p class="mb-2"><strong>Aktualna kwota:</strong> {{ formattedAmount }}</p>
        <p class="mb-2"><strong>Data:</strong> {{ invoice.date }}</p>
        <p class="mb-2"><strong>Utworzone przez:</strong> {{ invoice.createdBy }}</p>
      </div>

      <b-form @submit.prevent="confirmEdit">
        <b-form-group label="Nowa kwota">
          <b-form-input
            v-model="newAmountInput"
            type="text"
            required
            placeholder="Nowa kwota"
            :state="isAmountValid"
            @input="validateAmount"
          ></b-form-input>
          <b-form-invalid-feedback v-if="!isAmountValid && newAmountInput">
            {{ getAmountErrorMessage }}
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Powód korekty">
          <b-form-textarea
            v-model.trim="reason"
            placeholder="Powód korekty"
            rows="3"
            required
          ></b-form-textarea>
        </b-form-group>
        <div class="text-right mt-4">
          <b-button 
            variant="primary" 
            class="px-4"
            type="submit" 
            :disabled="!validateForm || loading"
          >
            Koryguj kwotę
          </b-button>
          <b-button 
            variant="link" 
            @click="cancelEdit" 
            :disabled="loading"
            class="ml-2"
          >
            Zamknij
          </b-button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>



<style scoped>
/* Opcjonalne styles specyficzne dla komponentu */
</style> 
<script>

import Repository from "../../app/repository/repository-factory";
import EventBus from "@/event-bus";
import NotificationMixin from "@/mixins/notificationMixin";

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");

export default {
  mixins: [NotificationMixin],
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationComment: {
      required: true
    },
  },
  data() {
    return {
      isBusy: false,
      submitted: false,
      form: {
        comment: this.applicationComment,
      },
    };
  },
  methods: {
    async changeComment() {
      this.formSubmit();
      const payload = { comment: this.form.comment };
      try {
        await ApplicationApplicationRepository.changeComment(this.applicationId, payload);
        EventBus.$emit("applicationChanged", "");
        this.notifySuccess("Komentarz został zaktualizowany!", { timer: 500 });
        this.inProgress = false;
      } catch (error) {
        this.notifyError(error.response.data.message);
        this.inProgress = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit() {
      this.submitted = true;
    }
  }
};
</script>
<template>
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-12 col-lg-12">
        <b-form-textarea
          id="comment"
          v-model="form.comment"
          placeholder="Tutaj wpisz komentarz..."
          rows="6"
        ></b-form-textarea>
      </div>
      </div>
    <div class="row mt-2">
      <div class="col-md-12 col-lg-12">
        <div class="float-sm-right">
          <button type="button" v-on:click="changeComment"
                  class="btn btn-primary waves-effect waves-light">Zapisz komentarz
            <template v-if="isBusy">
              <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
            </template>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

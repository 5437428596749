<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/pl';
import EventBus from '../../event-bus'

import Repository from "../../app/repository/repository-factory";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import NotificationMixin from "@/mixins/notificationMixin";
import floatInputMixin from '@/mixins/floatInputMixin';

const ApplicationApplicationRepository = Repository.get("ApplicationApplicationRepository");
const AiRepository = Repository.get("AiRepository");

export default {
  components: {DatePicker},
  mixins: [NotificationMixin, floatInputMixin],
  props: {
    applicationId: {
      type: String,
      required: true
    },
    applicationExpectedVersion: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      isBusy: false,
      isProcessingAI: false,
      submitted: false,
      maxFileSize: 9 * 1024 * 1024, // 9MB w bajtach
      form: {
        number: '',
        date: '',
        amount: '',
        file: null
      },
      isDataRecognized: false
    };
  },
  validations: {
    form: {
      number: {required, minLength: minLength(5), maxLength: maxLength(32) },
      date: {required},
      amount: {
        required,
        float: value => /^\d{1,10}([,.]\d{0,2})?$/.test(value)
      },
      file: {
        maxSize(file) {
          if (!file) return true;
          return file.size <= this.maxFileSize;
        }
      }
    }
  },
  methods: {
    async handleFileChange(file) {
      // Zawsze resetujemy dane formularza przy zmianie pliku
      this.form.number = '';
      this.form.date = '';
      this.form.amount = '';
      this.isDataRecognized = false;

      if (!file) {
        return;
      }

      if (file.size > this.maxFileSize) {
        this.form.file = null;
        this.notifyError(`Plik jest zbyt duży. Maksymalny rozmiar to ${(this.maxFileSize / (1024 * 1024)).toFixed(0)} MB`);
        return;
      }

      this.form.file = file;
      await this.processFileWithAI(file);
    },

    async processFileWithAI(file) {
      try {
        this.isProcessingAI = true;
        const formData = new FormData();
        formData.append('file', file);

        const { data } = await AiRepository.recognizeInvoice(formData);
        
        // Aktualizacja pól formularza
        this.form.number = data.invoiceNumber;
        this.form.date = data.invoiceDate;
        this.form.amount = data.invoiceFinalPrice.toFixed(2).toString();
        
        this.isDataRecognized = true;
        this.notifySuccess('Prosimy o weryfikację rozpoznanych danych przed dodaniem faktury');

      } catch (error) {
        console.error('Błąd podczas przetwarzania faktury:', error);
        this.notifyError('Nie udało się automatycznie rozpoznać danych z faktury. Proszę wprowadzić dane ręcznie.');
      } finally {
        this.isProcessingAI = false;
      }
    },

    async addInvoiceToApplication() {
      this.formSubmit();
      if (this.$v.form.$error) {
        return false;
      }
      this.isBusy = true;

      const formData = new FormData();
      formData.append('number', this.form.number);
      formData.append('date', this.form.date);
      formData.append('amount', this.form.amount);
      formData.append('version', this.applicationExpectedVersion);
      
      if (this.form.file) {
        formData.append('file', this.form.file);
      }

      try {
        await ApplicationApplicationRepository.addInvoiceToApplication(this.applicationId, formData);
        EventBus.$emit("applicationChanged", "");
        this.notifySuccess("Faktura została dodana!");
        // Reset formularza
        this.form.number = "";
        this.form.date = "";
        this.form.amount = "";
        this.form.file = null;
        this.isDataRecognized = false;
      } catch (error) {
        this.notifyError(error.response.data.message);
      } finally {
        this.submitted = false;
        this.isBusy = false;
      }
    },
    formSubmit() {
      this.submitted = true;
      this.$v.form.$touch()
    },
    handleAmountInput(event) {
      this.form.amount = this.handleFloatInput(event);
    },
  }
};
</script>
<template v-if="isBusy === false">
  <form class="needs-validation" @submit.prevent="formSubmit">
    <div class="row">
      <div class="col-md-3 col-lg-3">
        <div class="form-group">
          <div class="input-group" :class="{ 'is-processing': isProcessingAI }">
            <b-form-file
              v-model="form.file"
              placeholder="Wybierz plik..."
              drop-placeholder="Przeciągnij plik tutaj!"
              browse-text="Plik"
              accept=".pdf,.jpg,.jpeg,.png"
              :size="String(maxFileSize)"
              @input="handleFileChange"
              :class="{ 'is-invalid': submitted && $v.form.file.$error }"
              :disabled="isProcessingAI"
            ></b-form-file>
            <div class="input-group-append">
              <span 
                class="input-group-text"
                :class="{ 'text-info': !isProcessingAI, 'text-primary': isProcessingAI }"
                v-b-tooltip.hover="{ 
                  title: isProcessingAI 
                    ? 'Trwa rozpoznawanie danych z faktury...' 
                    : 'Załącznik jest opcjonalny. Obsługiwane pliki: .pdf, .jpg, .jpeg, .png. Maksymalny rozmiar: ' + (maxFileSize / (1024 * 1024)) + ' MB' 
                }"
              >
                <b-spinner v-if="isProcessingAI" small></b-spinner>
                <i v-else class="bx bx-info-circle"></i>
              </span>
            </div>
          </div>
          <div v-if="submitted && $v.form.file.$error" class="invalid-feedback">
            <span v-if="!$v.form.file.maxSize">
              Plik jest zbyt duży. Maksymalny dozwolony rozmiar to {{ (maxFileSize / (1024 * 1024)) }} MB.
            </span>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-lg-3">
        <div class="form-group">
          <input
            id="number"
            v-model="form.number"
            type="text"
            class="form-control"
            placeholder="Numer faktury"
            value=""
            :class="{ 'is-invalid': submitted && $v.form.number.$error }"
          />
          <div v-if="submitted && $v.form.number.$error" class="invalid-feedback">
            <span v-if="!$v.form.number.required">Ta wartość jest wymagana.</span>
            <span v-if="!$v.form.number.minLength"> Numer musi posiadać minimum 5 znaków.</span>
            <span v-if="!$v.form.number.maxLength"> Numer nie może być dłuższy niż 32 znaki.</span>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-lg-2">
        <div class="form-group">
          <input
            id="roadCardAmount"
            v-model="form.amount"
            type="text"
            class="form-control"
            placeholder="Kwota faktury"
            @input="handleAmountInput"
            value=""
            :class="{ 'is-invalid': submitted && $v.form.amount.$error }"
          />
          <div v-if="submitted && $v.form.amount.$error" class="invalid-feedback">
            <span v-if="!$v.form.amount.required">Ta wartość jest wymagana.</span>
            <span v-if="!$v.form.amount.float"> Wpisz poprawną kwotę (max. 2 miejsca po przecinku)</span>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-lg-2">
        <div class="form-group">
          <date-picker
            id="date"
            v-model="form.date"
            placeholder="Data faktury"
            :first-day-of-week="1"
            value-type="format"
            :class="{ 'is-invalid': submitted && $v.form.date.$error }"
            lang="pl">
          </date-picker>
          <div v-if="submitted && $v.form.date.$error" class="invalid-feedback">
            <span v-if="!$v.form.date.required">Ta wartość jest wymagana.<br></span>
          </div>
        </div>
      </div>
      <div class="col-md-2 col-lg-2">
        <div class="form-group">
          <button type="button" v-on:click="addInvoiceToApplication"
                  class="btn btn-primary waves-effect waves-light w-100">
             Dodaj fakturę
            <template v-if="isBusy">
              <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
            </template>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<style scoped>
/* Wyświetlanie komunikatów błędów */
.invalid-feedback {
  display: block !important;
}

/* Czerwona ramka dla nieprawidłowego pola */
.is-invalid {
  border-color: #dc3545;
}

/* Ukrycie przycisku "Przeglądaj" */
::v-deep .custom-file-label::after {
  display: none;
}

/* Stylowanie kontrolki pliku podczas przetwarzania */
.input-group.is-processing {
  opacity: 0.7;
  pointer-events: none;
}

::v-deep .custom-file-label {
  padding-right: 0.75rem;
  border-radius: 0.25rem 0 0 0.25rem !important;
  border-right: none;
}

.input-group-text {
  border-radius: 0 0.25rem 0.25rem 0;
  transition: all 0.3s ease;
  padding: 0.47rem 0.75rem;
}
</style>
